import React from "react";
import { useAuthTokenWithAudience } from "@busie/core";
import { history } from "@busie/utils";
import { observer } from "mobx-react-lite";

import { PageHeader } from "./ui";
import { QuoteForm, QuoteFormStore as store, StepName } from "@busie/features";
import { useFlags } from "launchdarkly-react-client-sdk";

export const stepList: StepName[] = [
  "Customer",
  "Trip",
  "Route",
  "Departures",
  "Summary",
];

export const AddQuotePage: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const nav = store.pageNavigation;
    const { useMetricSystem } = useFlags<{ useMetricSystem: boolean }>();
    const customersAuthToken = useAuthTokenWithAudience("customers");
    const pathfinderAuthToken = useAuthTokenWithAudience("pathfinder");
    const tripsAuthToken = useAuthTokenWithAudience("trips");
    const quotesAuthToken = useAuthTokenWithAudience("quotesAndBookings");
    const rateServiceAuthToken = useAuthTokenWithAudience("rates");

    const handlerNavBack = () => {
      nav.currentStep === 0
        ? history.push("/quotes-and-bookings/quotes")
        : nav.goPrev();
    };
    return (
      <div className="add-quote-page">
        <PageHeader title="Add quote" onNavBack={handlerNavBack} />
        <QuoteForm
          customersAuthToken={customersAuthToken}
          pathfinderAuthToken={pathfinderAuthToken}
          tripsAuthToken={tripsAuthToken}
          quotesAuthToken={quotesAuthToken}
          rateServiceAuthToken={rateServiceAuthToken}
          stepList={stepList}
          useMetricSystem={useMetricSystem}
          // a fine approximation for now, since only our mx client is using the metric system
          locale={useMetricSystem ? "es-mx" : "en-us"}
        />
      </div>
    );
  });
