import { AnalyticsEmptyState, H3, H4, NoContentBlock } from "@busie/ui-kit";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export const NoContent: React.FC = () => {
  const planSettingsPath = "/organization-settings/plan";
  return (
    <NoContentBlock
      icon={<AnalyticsEmptyState />}
      text="No analytics available to display..."
      controls={null}
    >
      <H3 sx={{ textAlign: "center" }}>No analytics available to display...</H3>
      <H3 weight={500}>Have you subscribed to Busie Pro?</H3>
      <H4>
        You can upgrade your subscription{" "}
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to={planSettingsPath}
          sx={{ fontWeight: 800 }}
        >
          here
        </Link>
        .
      </H4>
    </NoContentBlock>
  );
};
