import { FC, PropsWithChildren } from "react";
import { Tabs, TabsList } from "./ui";
import { DASHBOARD_OPTIONS, useDashboardElements } from "./model";

export const Widget: FC<PropsWithChildren<{ defaultDashboard?: string }>> = ({
  defaultDashboard = DASHBOARD_OPTIONS[0].id,
}): JSX.Element => {
  const { tabElements, panelElements } = useDashboardElements();

  return (
    <Tabs defaultValue={defaultDashboard}>
      <TabsList>{tabElements}</TabsList>
      {panelElements}
    </Tabs>
  );
};
