import { FlexContainer } from "@busie/ui-kit";
import { useUserContext } from "@busie/core";
import { FC, PropsWithChildren } from "react";

import { AnalyticsPageContent } from "./ui";

export const Page: FC<PropsWithChildren<unknown>> = () => {
  const { isPremium } = useUserContext();

  return (
    <FlexContainer
      align="center"
      justify="center"
      px={2}
      py={2}
      h="100%"
      fullWidth
      bordered
      sx={{ marginY: "16px", maxWidth: "1360px", alignSelf: "center" }}
    >
      <AnalyticsPageContent isPremium={isPremium} />
    </FlexContainer>
  );
};
