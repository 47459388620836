import {
  EmbeddedAnalyticsResponse,
  fetchAnalyticsEmbeddingUrl,
} from "@busie/api";
import { useQuery } from "react-query";

type UseEmbeddedAnalyticsParams = {
  authToken: string;
  dashboard: string;
};

export const EMBEDDED_ANALYTICS_QUERY_KEY = "EMBEDDED_ANALYTICS";

export const useEmbeddedAnalytics = ({
  authToken,
  dashboard,
}: UseEmbeddedAnalyticsParams): EmbeddedAnalyticsResponse => {
  const { data, isLoading, isFetching, isError } = useQuery(
    [EMBEDDED_ANALYTICS_QUERY_KEY, dashboard],
    () => fetchAnalyticsEmbeddingUrl(authToken, { dashboard }),
    {
      refetchOnWindowFocus: false,
      enabled: !!authToken && !!dashboard,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  return {
    isLoading,
    isFetching,
    isError,
    embedUrl: data?.embedUrl,
  };
};
