import { env } from "@busie/utils";
import axios, { Method } from "axios";
import {
  EmbeddedAnalyticsRequest,
  EmbeddedAnalyticsResponse,
} from "../../dataTypes";

/**
 * Creates a request to the Embedded Analytics service.
 *
 * This method is designed to handle various HTTP methods (GET, POST, PATCH, PUT, DELETE)
 * to provide flexibility for future enhancements and potential reuse across different
 * parts of the application. Although the current feature only requires a GET request,
 * this implementation anticipates the need for other HTTP methods, promoting code reuse
 * and reducing redundancy.
 *
 * @param authToken - The authentication token to be used for the request.
 * @param formData - The request data containing the necessary parameters for generating the embed URL.
 * @param endpoint - The endpoint to be used for the request.
 * @param baseUrl - The base URL to be used for the request.
 * @param method - The HTTP method to be used for the request.
 * @returns A promise that resolves to the response data.
 */

export const createRequest = async <T, R>(
  authToken: string,
  formData: T,
  endpoint: string,
  baseUrl: string,
  method: Method = "GET"
): Promise<R> => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const requestUrl = `${baseUrl}/${endpoint}`;

  let response;
  switch (method) {
    case "GET":
      // Using formData as query parameters
      response = await axios.get(requestUrl, { headers, params: formData });
      break;
    case "POST":
      response = await axios.post(requestUrl, formData, { headers });
      break;
    case "PATCH":
      response = await axios.patch(requestUrl, formData, { headers });
      break;
    case "PUT":
      response = await axios.put(requestUrl, formData, { headers });
      break;
    case "DELETE":
      response = await axios.delete(requestUrl, { headers, data: formData });
      break;
    default:
      throw new Error(`Unsupported method: ${method}`);
  }

  return response.data;
};

/**
 * Fetches Sigma embed URLs.
 *
 * @param authToken - The authentication token to be used for the request.
 * @param formData - The request data containing the necessary parameters for generating the embed URL.
 * @returns A promise that resolves to a EmbeddedAnalyticsResponse containing the embed URL.
 */
export const fetchAnalyticsEmbeddingUrl = async (
  authToken: string,
  formData: EmbeddedAnalyticsRequest
): Promise<EmbeddedAnalyticsResponse> => {
  const data = await createRequest<
    EmbeddedAnalyticsRequest,
    EmbeddedAnalyticsResponse
  >(
    authToken,
    formData,
    "ea/embed-url/",
    env("BUSIE_EMBEDDED_ANALYTICS_SERVICE_API_URL"),
    "GET"
  );

  return data;
};
