import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { styled } from "@mui/system";

export const TabsList = styled(BaseTabsList)(({ theme }) => {
  const {
    palette: { black },
  } = theme;
  return {
    display: "flex",
    alignItems: "center",
    padding: "3px 4px",
    background: black.plus100,
    border: `1px solid ${black.plus70}`,
    boxSizing: "border-box",
    borderRadius: "6px",
  };
});
