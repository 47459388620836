import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import Button from "../Button";
import FormContainer from "../FormContainer";
import SearchCustomerInput from "../SearchCustomerInput";
import CreateCustomerDataFields from "./CreateCustomerDataFields";
import ShowCustomerDataFields from "./ShowCustomerDataFields";
import RadioGroupStyled from "./styled/RadioGroupStyled";

import store from "../../store";

import { SelectionMode } from "../../store/customerForm/store.types";

import { createCustomer } from "@busie/api";
import { useAmplitude, useUserContext } from "@busie/core";
import {
  ErrorMessage,
  RadioButton,
  RadioButtonChangeEvent,
} from "@busie/ui-kit";
import { Customer, Group } from "@busie/utils";
import { notificationStore } from "../../../../Notifications";

interface Props {
  tripsAuthToken?: string;
  children?: React.ReactNode;
  goNext: () => void;
}

const CustomerForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ ...props }: Props) => {
    const { customersAuthToken } = useUserContext();
    const customerForm = store.customerForm;
    const { user } = useAuth0();
    const { track } = useAmplitude();

    const setSelectionMode = (e: RadioButtonChangeEvent) => {
      customerForm.setCustomerSelectionMode(e.target.value as SelectionMode);
    };

    const selectCustomer = (value: Customer | null) => {
      customerForm.setCustomer(value);
      customerForm.setGroup(value ? ({ id: value?.groupId } as Group) : null);
    };

    const updateTrip = async (): Promise<void> => {
      if (!customerForm.editMode) return;

      const data = store.getTripFormData(user?.org_id);
      if (!data.legs.length) return;
      await store.departuresForm.submitForm(
        data,
        {
          tripsAuthToken: props.tripsAuthToken,
        },
        false
      );
      customerForm.setEditMode(false);
    };

    const getFormAction = (mode: SelectionMode) => {
      if (mode === "create_new_customer") {
        return async () => {
          customerForm.validateForm();

          if (!customerForm.validation.valid) {
            return;
          }

          let newCustomer: Customer;
          try {
            newCustomer = await createCustomer(
              customersAuthToken,
              customerForm.fields
            );
          } catch (e) {
            notificationStore.setNotificationFromError(e);
            return;
          }

          track("trip planning customer created");
          const organizationId = customerForm.fields.organization;
          const organization = customerForm.groups.find(
            (g) => g.id === organizationId
          );
          const newCustomerWithName = {
            ...newCustomer,
            groupName: organization?.name,
          };
          customerForm.setCustomer(newCustomerWithName as Customer);
          await updateTrip();
          props.goNext();
        };
      }
      return async () => {
        customerForm.validateForm();

        if (!customerForm.validation.valid) {
          return;
        }
        await updateTrip();
        props.goNext();
      };
    };

    React.useEffect(() => {
      customerForm.setCustomerSelectionMode("select_existing_customer");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <FormContainer title="Customer Information">
          <RadioGroupStyled
            value={customerForm.selectionMode}
            name="customer-selector"
            onChange={setSelectionMode}
          >
            <RadioButton
              value="select_existing_customer"
              label="Select existing customer"
            />
            <RadioButton
              value="create_new_customer"
              label="Create new customer"
            />
          </RadioGroupStyled>
          {customerForm.selectionMode === "select_existing_customer" && (
            <>
              <SearchCustomerInput
                setCustomer={selectCustomer}
                customer={customerForm.customer}
              />
              {!customerForm.customer && (
                <ErrorMessage>
                  {customerForm.validation.errors.customer}
                </ErrorMessage>
              )}
            </>
          )}
          {customerForm.selectionMode === "select_existing_customer" &&
            customerForm.customer !== null && (
              <ShowCustomerDataFields
                authToken={customersAuthToken}
                name={customerForm.customer?.name}
                groupName={customerForm.customer?.groupName}
                email={customerForm.customer?.email}
                phoneNumber={`${customerForm.customer.countryCode}${
                  customerForm.customer.areaCode
                }${customerForm.customer.phoneNumber?.slice(
                  0,
                  3
                )}${customerForm.customer.phoneNumber?.slice(3)}`}
              />
              // eslint-disable-next-line indent
            )}

          {customerForm.selectionMode === "create_new_customer" && (
            <CreateCustomerDataFields />
          )}
        </FormContainer>
        <Button
          fullWidth
          async
          onClick={getFormAction(customerForm.selectionMode)}
        >
          Continue
        </Button>
      </>
    );
  }
);

export default CustomerForm;
