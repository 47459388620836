import { QueryKey, useMutation, useQuery } from "react-query";

import {
  queryClient,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";
import { RouteRequest, createRouteRequest, fetchCustomer } from "@busie/api";

const CREATE_TRIP_QUERY_KEY: QueryKey = "CREATE_TRIP";

export const invalidateCreateTripQueries = () => {
  queryClient.invalidateQueries(CREATE_TRIP_QUERY_KEY);
};

export const useTripMainContact = (id: string) => {
  const authToken = useAuthTokenWithAudience("customers");

  const result = useQuery(
    [CREATE_TRIP_QUERY_KEY, "customer", id],
    () => fetchCustomer(authToken, id),
    {
      enabled: !!id && !!authToken,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useCreateTripRoute = () => {
  const authToken = useAuthTokenWithAudience("pathfinder");

  const mutation = useMutation(async (data: RouteRequest) => {
    const result = await createRouteRequest(authToken, data);

    return result;
  });

  return mutation;
};
