import { useMutation } from "react-query";

import { invalidatePaymentQueries } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";
import { createInvoice, CreateInvoiceParams } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";

export const useCreateInvoice = () => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const mutation = useMutation(async (data: CreateInvoiceParams) => {
    const invoice = await createInvoice(paymentsAuthToken, data);
    // invalidate queries -- qnb queries and payment-service queries
    // TODO: we aren't yet using react-query for qnb queries, so we can't invalidate them
    // for now we will need to use the store
    // queryClient.invalidateQueries(QNB_QUERY_KEY)
    invalidatePaymentQueries();
    return invoice;
  });

  return mutation;
};
