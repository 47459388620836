import { ETPAmenity } from "@busie/api";
import * as yup from "yup";

export const schema = yup.object({
  domain: yup.string().required("Please enter a valid URL"),
  hideInstantQuote: yup.boolean().required("required"),
  requestToBook: yup.boolean().required("required"),
  displayedAmenities: yup
    .array()
    .min(0)
    .max(Object.values(ETPAmenity).length)
    .of(yup.mixed().oneOf(Object.values(ETPAmenity))),
});
