import React, { useEffect } from "react";
import * as yup from "yup";
import { Container } from "./ui";
import {
  Button,
  Divider,
  FlexContainer,
  FormContainer,
  H1,
  H2,
  H3,
  H4,
  Loading,
  ProfilePictureBlock,
  Subtitle,
  TextInput,
  ToggleSwitch,
} from "@busie/ui-kit";
import { useAuth0 } from "@auth0/auth0-react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "@busie/utils";
import { useUpdateProfile } from "./model";
import { useAmplitude } from "@busie/core";
import { notificationStore } from "@busie/features";

export const Page: React.FC = () => {
  const { user, isLoading, logout } = useAuth0<User>();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<{ name: string; enableQCN: boolean }>({
    mode: "onChange",
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        enableQCN: yup.boolean().required(),
      })
    ),
    defaultValues: {
      name: user?.name ?? "",
      enableQCN:
        user?.["https://getbusie.com/user_metadata"]?.enableQCN ?? true,
    },
  });

  useEffect(() => () => reset(), [reset]);

  const { track } = useAmplitude();

  const onError = (error: unknown) =>
    notificationStore.setNotificationFromError(error);

  const onSuccess = (user: User) => {
    track("user profile updated", { user });
    notificationStore.setSuccessNotification(
      "Success!",
      "Profile updated successfullty. You may have to refresh the page to see the changes."
    );
  };

  const onSubmit = async (data: { name: string; enableQCN: boolean }) => {
    await updateProfile(data, {
      onSuccess,
      onError,
    });
  };

  return (
    <Container>
      <FlexContainer justify="space-between" fullWidth>
        <Subtitle>Profile</Subtitle>
        <FlexContainer columnGap={1}>
          <Button
            typestyle="secondary"
            size="small"
            onClick={() => logout({ returnTo: window.location.origin + "/" })}
          >
            Logout
          </Button>
        </FlexContainer>
      </FlexContainer>

      <Divider />
      {isLoading ? (
        <Loading />
      ) : (
        <FlexContainer direction="column" rowGap={2} fullWidth>
          <FlexContainer columnGap={2} align="center">
            <ProfilePictureBlock user={user} />
            <FlexContainer direction="column" rowGap={1}>
              <H1 margin="0px">{user?.name}</H1>
              <H2>{user?.email}</H2>
            </FlexContainer>
          </FlexContainer>

          <FormContainer title="Preferences" fullWidth>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FlexContainer
                direction="row"
                justify="flex-start"
                columnGap={2}
                fullWidth
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      label="Name"
                      fullWidth
                      errorMessage={errors.name?.message}
                    />
                  )}
                />
                <TextInput
                  label="Email"
                  value={user?.email}
                  fullWidth
                  disabled
                />
              </FlexContainer>
              <br />

              <Controller
                name="enableQCN"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FlexContainer align="center" rowGap={2} columnGap={2} pb={3}>
                    <p>Quote Creation Notifications: {value ? "On" : "Off"}</p>
                    <ToggleSwitch
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      disabled={isSubmitting}
                    />
                  </FlexContainer>
                )}
              />

              <div className="form-submit-control">
                <Button
                  typestyle="primary"
                  size="big"
                  type="submit"
                  disabled={!isDirty || !isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </form>
          </FormContainer>
        </FlexContainer>
      )}
    </Container>
  );
};
