import { sendInvoice } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";
import { useMutation } from "react-query";
import { invalidatePaymentQueries } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";

export const useSendInvoice = () => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const mutation = useMutation(async (invoiceId: string) => {
    const invoice = await sendInvoice(paymentsAuthToken, invoiceId);
    // invalidate queries -- qnb queries and payment-service queries
    // TODO: we aren't yet using react-query for qnb queries, so we can't invalidate them
    // invalidateQnbQueries();
    invalidatePaymentQueries();

    return invoice;
  });

  return mutation;
};
