import { useMutation } from "react-query";

import { voidInvoice, VoidInvoiceData } from "@busie/api";
import { invalidatePaymentQueries } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";
import { useAuthTokenWithAudience } from "@busie/core";

export const useVoidInvoice = () => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const mutation = useMutation(
    async ({
      invoiceId,
      voidInvoiceData,
    }: {
      invoiceId: string;
      voidInvoiceData: VoidInvoiceData;
    }) => {
      const invoice = await voidInvoice(
        paymentsAuthToken,
        invoiceId,
        voidInvoiceData
      );

      invalidatePaymentQueries();

      return invoice;
    }
  );

  return mutation;
};
