import { UpdateDriverParams, editDriver } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";
import { useMutation } from "react-query";
import { invalidateDriversQueries } from "~/drivers/model";

export const useEditDriver = (id: string) => {
  const driversAuthToken = useAuthTokenWithAudience("drivers");

  const mutation = useMutation(async (payload: UpdateDriverParams) => {
    const driver = await editDriver(driversAuthToken, id, payload);

    invalidateDriversQueries();
    return driver;
  });

  return mutation;
};
