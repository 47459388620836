import React from "react";

export const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <svg
      width="189"
      height="189"
      viewBox="0 0 189 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="94.5" cy="94.5" r="94.5" fill="#F4F5F6" />
      <mask
        id="mask0_11008_219659"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="189"
        height="189"
      >
        <circle cx="94.5" cy="94.5" r="94.5" fill="#F4F5F6" />
      </mask>
      <g mask="url(#mask0_11008_219659)">
        <g filter="url(#filter0_d_11008_219659)">
          <circle
            cx="50.1293"
            cy="61.4286"
            r="16.183"
            fill="url(#paint0_linear_11008_219659)"
          />
        </g>
        <path
          d="M72.1165 60.4278C72.1412 60.9796 71.6888 61.4283 71.1365 61.4283C65.1066 61.4283 61.8744 61.4283 51.1324 61.4283C50.5801 61.4283 50.13 60.9785 50.13 60.4262C50.13 49.7685 50.1299 45.1073 50.13 40.4194C50.13 39.8671 50.5787 39.4171 51.1304 39.4418C62.4896 39.9499 71.6083 49.0686 72.1165 60.4278Z"
          fill="url(#paint1_linear_11008_219659)"
        />
        <rect
          x="41"
          y="105.206"
          width="16"
          height="40"
          rx="2"
          fill="url(#paint2_linear_11008_219659)"
        />
        <g filter="url(#filter1_d_11008_219659)">
          <rect
            x="65"
            y="93.4194"
            width="15.5357"
            height="51.7857"
            rx="2"
            fill="url(#paint3_linear_11008_219659)"
          />
        </g>
        <rect
          x="88.5361"
          y="86.9463"
          width="15.5357"
          height="58.2589"
          rx="2"
          fill="url(#paint4_linear_11008_219659)"
        />
        <g filter="url(#filter2_d_11008_219659)">
          <rect
            x="112.071"
            y="80.4731"
            width="15.5357"
            height="64.7321"
            rx="2"
            fill="url(#paint5_linear_11008_219659)"
          />
        </g>
        <rect
          x="135.607"
          y="74"
          width="15.5357"
          height="71.2054"
          rx="2"
          fill="url(#paint6_linear_11008_219659)"
        />
        <rect
          x="85.7324"
          y="45.2456"
          width="67.3214"
          height="5.17857"
          rx="2"
          fill="url(#paint7_linear_11008_219659)"
        />
        <rect
          x="86"
          y="55"
          width="41.4286"
          height="5.17857"
          rx="2"
          fill="url(#paint8_linear_11008_219659)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11008_219659"
          x="19.9463"
          y="32.2456"
          width="62.3662"
          height="62.3662"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.0941176 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11008_219659"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11008_219659"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_11008_219659"
          x="56"
          y="84.4194"
          width="35.5361"
          height="71.7856"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.0941176 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11008_219659"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11008_219659"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_11008_219659"
          x="103.071"
          y="71.4731"
          width="35.5361"
          height="84.7319"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.0941176 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11008_219659"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11008_219659"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_11008_219659"
          x1="61.3115"
          y1="47.6854"
          x2="33.8383"
          y2="50.3069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF5969" />
          <stop offset="1" stop-color="#FF192F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11008_219659"
          x1="55.6808"
          y1="48.7666"
          x2="72.7215"
          y2="38.3414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222222" />
          <stop offset="1" stop-color="#444444" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11008_219659"
          x1="45.0354"
          y1="122.194"
          x2="61.0983"
          y2="118.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222222" />
          <stop offset="0.994792" stop-color="#5E5E5E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11008_219659"
          x1="78.1353"
          y1="97.3231"
          x2="64.839"
          y2="97.7037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF5969" />
          <stop offset="1" stop-color="#FF192F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11008_219659"
          x1="92.4544"
          y1="111.689"
          x2="108.557"
          y2="109.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222222" />
          <stop offset="0.994792" stop-color="#5E5E5E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11008_219659"
          x1="125.207"
          y1="85.3528"
          x2="111.906"
          y2="85.6573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF5969" />
          <stop offset="1" stop-color="#FF192F" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11008_219659"
          x1="139.526"
          y1="104.241"
          x2="155.767"
          y2="102.073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222222" />
          <stop offset="0.994792" stop-color="#5E5E5E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11008_219659"
          x1="102.712"
          y1="47.4449"
          x2="103.827"
          y2="38.5782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222222" />
          <stop offset="1" stop-color="#737373" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11008_219659"
          x1="96.4488"
          y1="57.1993"
          x2="98.2154"
          y2="48.5534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222222" />
          <stop offset="1" stop-color="#737373" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyState;
