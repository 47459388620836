import { styled, Theme } from "@mui/system";
interface Props {
  theme?: Theme;
}

const LayoutStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    height: "100%",
    backgroundColor: palette?.black?.plus85,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  };
});

export default LayoutStyled;
