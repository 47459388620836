import { QueryKey, useQuery } from "react-query";

import {
  getInvoiceById,
  listInvoices,
  ListInvoicesParams,
  getInvoicePreviewLink,
} from "@busie/api";
import {
  queryClient,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";
import { InvoiceStatus } from "@busie/utils";

const INVOICES_QUERY_KEY: QueryKey = "INVOICES";

export const invalidatePaymentQueries = () => {
  queryClient.invalidateQueries(INVOICES_QUERY_KEY);
};

export const useListInvoices = (params: ListInvoicesParams = {}) => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [INVOICES_QUERY_KEY, params],
    () => listInvoices(paymentsAuthToken, params),
    {
      enabled: !!paymentsAuthToken,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useGetInvoiceById = (
  invoiceId = "",
  refetchInterval = 5 * 1000 * 60
) => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [INVOICES_QUERY_KEY, invoiceId],
    () => getInvoiceById(paymentsAuthToken, invoiceId),
    {
      enabled: !!paymentsAuthToken && !!invoiceId,
      staleTime: STALE_TIMES.DEFAULT,
      refetchInterval,
    }
  );

  return result;
};

export const useInvoicePreview = (
  invoiceId = "",
  invoiceStatus?: InvoiceStatus
) => {
  const authToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [INVOICES_QUERY_KEY, invoiceId],
    () => getInvoicePreviewLink(authToken, invoiceId),
    {
      enabled:
        !!authToken &&
        !!invoiceId &&
        [
          "FINALIZED",
          "SENDING",
          "SEND_FAILED",
          "SENT",
          "ATTEMPTING_PAYMENT",
          "PAYMENT_FAILED",
          "PAID",
        ].includes(invoiceStatus || ""),
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};
