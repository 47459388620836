/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { fetchLegsPrices } from "@busie/api";
import { LegPrice, TripLeg } from "@busie/utils";
import {
  DatePicker,
  FlexContainer,
  Loading,
  palette,
  PlaceField,
  Select,
  theme,
  TimeInput,
  NoteText,
  IconButton,
  DeleteIcon,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";
import {
  centsToDollars,
  currencyFormatter,
  metersToMiles,
  dayjsExt,
} from "@busie/utils";
import cx from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { store } from "../../../model";
import { routeStopToLocation } from "./lib";
import { WaypointStyled } from "./styled";
import { Dayjs } from "dayjs";
import { useAuthTokenWithAudience } from "@busie/core";

type WaypointType = "dispatch" | "waypoint" | "laststop";

interface Props {
  type: WaypointType;
  title: string;
  index?: number;
  data: TripLeg;
  lastStop?: boolean;
  isAdmin?: boolean;
  onRemove?: (index: number) => void;
}

const getAddressInputLabel = (type: WaypointType, index?: number): string => {
  if (type === "dispatch") return "Dispatch";
  if (index === 0) {
    return "Pickup";
  } else {
    return `Stop ${index}`;
  }
};

export const Waypoint: React.FC<React.PropsWithChildren<Props>> = observer(
  (props) => {
    const [legPrices, setLegPrices] = React.useState<LegPrice[]>();
    const authToken = useAuthTokenWithAudience("rates");
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

    React.useEffect(() => {
      const fetchPrice = async () => {
        const prices = await fetchLegsPrices(
          props.data._legPrice.map((price) => price.legPriceId),
          authToken
        );
        setLegPrices(prices);
      };
      if (authToken) {
        fetchPrice();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    const location = props.lastStop
      ? props.data._destinationLocation
      : props.data._startLocation;

    const date = new Date(props.data._departureDateTime);
    const time = dayjsExt(props.data._departureDateTime);

    const getAddressTitle = (): string => {
      if (props.lastStop) {
        return "Last Stop";
      } else if (props.index === 0) {
        return "Pickup Location";
      } else if (props.type === "dispatch") {
        return "Dispatch Location";
      } else {
        return `Stop ${props.index}`;
      }
    };

    const onDispatchChange = (locationId: string) => {
      store.setDispatchLocation(locationId);
    };

    let currentDepartureDateTime = new Date(props.data._departureDateTime);
    const onDepartureDateChange = (value: Date) => {
      currentDepartureDateTime = new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        currentDepartureDateTime.getHours(),
        currentDepartureDateTime.getMinutes()
      );
      store.updateLegDepartureDateTime(
        props.data._id,
        currentDepartureDateTime
      );
    };

    const onDepartureTimeChange = (value: Dayjs | null) => {
      currentDepartureDateTime = new Date(
        currentDepartureDateTime.getFullYear(),
        currentDepartureDateTime.getMonth(),
        currentDepartureDateTime.getDate(),
        value?.hour() || 0,
        value?.minute() || 0
      );
      store.updateLegDepartureDateTime(
        props.data._id,
        currentDepartureDateTime
      );
    };

    const disableDepartureEdit =
      props.type === "dispatch" || !props.data._nextLeg;

    const removeButton = ({ onRemove }: { onRemove: () => void }) => (
      <IconButton
        onClick={onRemove}
        type="button"
        style={{
          marginTop: "10px",
          marginLeft: "20px",
          height: "80px",
        }}
        icon={<DeleteIcon />}
      />
    );

    if (!legPrices) {
      return (
        <FlexContainer
          justify="center"
          textAlign="center"
          fullWidth
          h={isMobile ? "310px" : "125px"}
        >
          <Loading />
        </FlexContainer>
      );
    }
    return (
      <WaypointStyled
        waypointType={props.type}
        isLastStop={!!props.lastStop}
        isMobile={isMobile}
      >
        <div>
          <div
            className={cx([
              "arrow",
              props.type,
              { "show-arrow": !props.lastStop },
            ])}
          >
            <span className="progress"></span>
          </div>
        </div>
        <FlexContainer direction="column" rowGap={1} fullWidth>
          <FlexContainer
            direction={isMobile ? "column" : "row"}
            justify="space-between"
            fullWidth
          >
            {getAddressTitle()}
            <NoteText color={palette.black.plus30}>
              Leg distance ({`${metersToMiles(props.data._meters)} mi`}) | Leg
              price (
              {currencyFormatter(
                centsToDollars(
                  legPrices.reduce((acc, price) => acc + price._subtotal, 0)
                )
              )}
              )
            </NoteText>
          </FlexContainer>
          <FlexContainer direction={isMobile ? "column" : "row"} columnGap={2}>
            {props.type !== "dispatch" ? (
              <PlaceField
                width="294px"
                fieldValue={location.address}
                onSelect={(selected) => {
                  store.updateLeg(
                    props.data._id,
                    routeStopToLocation(selected)
                  );
                }}
                label={getAddressInputLabel(props.type, props.index)}
                margin="8px 0px"
              />
            ) : (
              <Select
                label={getAddressInputLabel(props.type, props.index)}
                disabled={!props.isAdmin || props.lastStop}
                items={store.dispatchLocations.map((dl) => ({
                  name: dl.name,
                  value: dl.id,
                }))}
                sx={{ width: "294px" }}
                value={store.dispatchLocation}
                onChange={(res) => onDispatchChange(res.target.value)}
              />
            )}
            {(!props.lastStop && (
              <>
                <DatePicker
                  value={date}
                  minDate={new Date()}
                  disablePast={true}
                  onChange={onDepartureDateChange}
                  label="Departure date"
                  fixedWidth="170px"
                  disabled={disableDepartureEdit}
                />
                <div
                  style={{
                    marginTop: isMobile ? "16px" : "8px",
                    width: "100px",
                  }}
                >
                  <TimeInput
                    value={time}
                    onChange={onDepartureTimeChange}
                    label="Departure time"
                    disabled={disableDepartureEdit}
                  />
                </div>
                {props.onRemove &&
                  !disableDepartureEdit &&
                  removeButton({
                    onRemove: () =>
                      props.onRemove && props.onRemove(props.index! + 1),
                  })}
              </>
            )) ||
              null}
          </FlexContainer>
        </FlexContainer>
      </WaypointStyled>
    );
  }
);

export default Waypoint;
