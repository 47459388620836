export const SubscriptionTypes = {
  FREEMIUM: "FREEMIUM",
  STARTER: "STARTER",
  BASIC: "BASIC",
  PRO: "PRO",
  ENTERPRISE: "ENTERPRISE",
} as const;

export type SubscriptionType =
  typeof SubscriptionTypes[keyof typeof SubscriptionTypes];

export interface Plan {
  _id: string;
  subscriptionType: SubscriptionType;
}
