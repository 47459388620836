import { Tabs as BaseTabs } from "@mui/base/Tabs";
import { styled } from "@mui/system";

export const Tabs = styled(BaseTabs)(() => {
  return {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
});
