import { updateUserProfile } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";
import { useMutation } from "react-query";

export const useUpdateProfile = () => {
  const token = useAuthTokenWithAudience("profile");

  return useMutation(async (data: { name: string; enableQCN: boolean }) => {
    const result = await updateUserProfile(token, data);

    return result;
  });
};
