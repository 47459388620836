import React from "react";
import { observer } from "mobx-react-lite";

import { useAuthTokenWithAudience, usePagination } from "@busie/core";

import { ArchivedQuotesTable, PageHeader } from "../entity";
import { QuoteDetailsDrawer } from "../widgets";
import { archivedQuotesStore } from "../model";

interface Props {
  isCustomerView?: boolean;
}

export const ArchivedQuotesPage: React.FC<React.PropsWithChildren<Props>> =
  observer(({ isCustomerView }) => {
    const tripsAuthToken = useAuthTokenWithAudience("trips");
    const quotesAuthToken = useAuthTokenWithAudience("quotesAndBookings");
    const customersAuthToken = useAuthTokenWithAudience("customers");
    const dispatchLocationsAuthToken =
      useAuthTokenWithAudience("dispatchLocations");

    const [currentPage, setPage] = usePagination();

    React.useEffect(() => {
      archivedQuotesStore.setAuthTokens({
        trips: tripsAuthToken,
        quotes: quotesAuthToken,
        customers: customersAuthToken,
        dispatchLocations: dispatchLocationsAuthToken,
      });
      if (
        archivedQuotesStore.items.length === 0 &&
        tripsAuthToken &&
        quotesAuthToken &&
        customersAuthToken &&
        dispatchLocationsAuthToken
      ) {
        archivedQuotesStore.setItems(currentPage);
      }
    }, [
      tripsAuthToken,
      quotesAuthToken,
      customersAuthToken,
      dispatchLocationsAuthToken,
      currentPage,
    ]);

    React.useEffect(() => {
      return () => {
        archivedQuotesStore.reset();
      };
    }, []);

    return (
      <>
        {!isCustomerView && <PageHeader />}
        <ArchivedQuotesTable
          quotes={archivedQuotesStore.items}
          quotesResponse={archivedQuotesStore.quotesResponse || null}
          dispatchLocations={archivedQuotesStore.dispatchLocations}
          currentPage={currentPage}
          setPage={setPage}
        />

        <QuoteDetailsDrawer
          onListUpdate={() => archivedQuotesStore.setItems(currentPage)}
          authTokens={{
            trips: tripsAuthToken,
            quotes: quotesAuthToken,
            customers: customersAuthToken,
          }}
          isCustomerView={isCustomerView}
        />
      </>
    );
  });
