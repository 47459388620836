import React, { FC, useEffect, useState } from "react";
import { Recipe } from "@busie/utils";
import {
  DrawerDetailsBlock,
  IconButton,
  Menu,
  PropertyString,
  MoreIcon,
} from "@busie/ui-kit";
import { prettifyRuleValue } from "~/rates/RateMenuPage/components/CookbooksTableView/CookbookView/RecipeView/helpers";
import { ruleTypes } from "~/rates/RateMenuPage/store/constants";
import store from "~/rates/RateMenuPage/store";
import DeleteRecipePopup from "~/rates/RateMenuPage/components/CookbookDetailsDrawer/DeleteRecipePopup";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthTokenWithAudience } from "@busie/core";

interface Props {
  recipe: Recipe;
}

const RECIPE_URL_KEY = "recipeId";

const RecipeInfoBlock: FC<React.PropsWithChildren<Props>> = ({ recipe }) => {
  const authToken = useAuthTokenWithAudience("rates");
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const ruleType = ruleTypes[recipe.rule.type];
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (searchParams.has(RECIPE_URL_KEY)) {
      //TODO logic to open on render
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = (deleted = false) => {
    if (deleted) {
      searchParams.delete("recipeId");
      history.replace({
        search: `?${searchParams.toString()}`,
      });
      store.rates.clearActiveRecipe();
    }
    setShowDeletePopup(false);
  };

  const MenuComponent = (
    <Menu
      top="-10%"
      right="0"
      toggler={
        <IconButton
          icon={<MoreIcon />}
          type="button"
          highlightOnHover={false}
        />
      }
      items={[
        {
          content: "Add ingredient",
          action: () => store.ingredientModal.openCreateModal(recipe.id),
        },
        {
          content: "Edit rule",
          action: () => store.recipeEdit.openRuleEditModal(recipe),
        },
        {
          content: "Rename recipe",
          action: () => store.recipeEdit.openNameEditModal(recipe),
        },
        {
          content: "Duplicate recipe",
          action: () =>
            store.createRecipeModal.openModalWithData(authToken, recipe),
        },
        {
          content: "Delete",
          action: () => setShowDeletePopup(true),
        },
      ]}
    />
  );
  return (
    <>
      <DrawerDetailsBlock name="Recipe Info" collapsible menu={MenuComponent}>
        <PropertyString name="Recipe">{recipe.name}</PropertyString>
        <PropertyString name="Rule">{ruleType}</PropertyString>
        <PropertyString name="Value">
          {prettifyRuleValue(recipe.rule)}
        </PropertyString>
      </DrawerDetailsBlock>
      <DeleteRecipePopup
        id={recipe.id}
        isPopupOpened={showDeletePopup}
        onClose={onClose}
      />
    </>
  );
};

export default RecipeInfoBlock;
