import { useQuery } from "react-query";

import { fetchGroups } from "@busie/api";
import {
  queryClient,
  CUSTOMERS_QUERY_KEY,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";
import { ORGANIZATIONS_PER_PAGE } from ".";

export const invalidateCustomersQueries = () => {
  queryClient.invalidateQueries(CUSTOMERS_QUERY_KEY);
};

interface CustomersOrganizationsParams {
  query?: string;
  currentPage: number;
}

export const useCustomersPageOrganizations = (
  { query, currentPage }: CustomersOrganizationsParams = { currentPage: 1 }
) => {
  const customersAuthToken = useAuthTokenWithAudience("customers");
  const skip = currentPage > 1 ? (currentPage - 1) * ORGANIZATIONS_PER_PAGE : 0;

  const result = useQuery(
    [
      CUSTOMERS_QUERY_KEY,
      "organizations",
      query ? query : undefined,
      skip,
      ORGANIZATIONS_PER_PAGE,
    ],
    () =>
      fetchGroups(customersAuthToken, {
        search: query ? query : undefined,
        skip,
        take: ORGANIZATIONS_PER_PAGE,
      }),
    {
      enabled: !!customersAuthToken,
      staleTime: STALE_TIMES.DEFAULT,
      keepPreviousData: true,
    }
  );

  return result;
};
