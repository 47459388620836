import { useMutation } from "react-query";
import { createPaymentLink, CreatePaymentLinkData } from "@busie/api";
import { invalidatePaymentQueries } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";
import { invalidateQnbQueries } from "~/QuotesAndBookingsPage/entity/model";
import { useAuthTokenWithAudience } from "@busie/core";

export const useCreatePaymentLink = () => {
  const qnbAuthToken = useAuthTokenWithAudience("quotesAndBookings");

  const mutation = useMutation(async (data: CreatePaymentLinkData) => {
    await createPaymentLink(qnbAuthToken, data);

    invalidateQnbQueries();
    invalidatePaymentQueries();
  });

  return mutation;
};
