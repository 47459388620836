import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { isOrganizationSettingsAllowed, User } from "@busie/utils";

import { FlexContainer, H1, H2, Hr, Loading, Text } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

import { SettingsContainer } from "~/settings/shared/ui";

import { Form } from "./ui";
import {
  useEmbeddedTripPlannerSettings,
  useUpdateEmbeddedTripPlannerSettings,
} from "./model";

export const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, isLoading } = useAuth0<User>();
  const history = useHistory();

  const {
    data: settings,
    isFetching,
    isError,
  } = useEmbeddedTripPlannerSettings(user?.org_id);

  const { mutateAsync: updateSettings, isLoading: isUpdating } =
    useUpdateEmbeddedTripPlannerSettings(settings?._id);

  React.useEffect(() => {
    if (isLoading || !user) return;

    if (!isOrganizationSettingsAllowed(user)) history.push("/");
  }, [isLoading, user, history]);

  if (isError)
    return (
      <SettingsContainer value="Embedded Trip Planner">
        <Text>
          We couldn't fetch your organization's Embedded Trip PLanner settings.
          Are you sure you have access?
        </Text>
      </SettingsContainer>
    );

  const showLoading = isLoading || isFetching || isUpdating || !settings;

  if (showLoading)
    return (
      <SettingsContainer value="Embedded Trip Planner">
        <Loading />
      </SettingsContainer>
    );

  return (
    <SettingsContainer value="Embedded Trip Planner">
      <div className="new-settings-content">
        <FlexContainer
          direction="row"
          justify="space-between"
          fullWidth
          align="center"
        >
          <H1>Embedded Trip Planner</H1>

          <FlexContainer direction="column" rowGap={2}>
            <FlexContainer direction="row" align="center" columnGap={2}>
              <H2>Your API Key:</H2>

              <Text>{settings._apiKey}</Text>
            </FlexContainer>

            <FlexContainer direction="row" align="center" columnGap={2}>
              <H2>Your Web Domain:</H2>

              <Text>{settings._domain}</Text>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <Hr />

        <Form
          settings={settings}
          onSubmit={async (data) => {
            await updateSettings(data, {
              onSuccess: () => {
                notificationStore.setNotification({
                  type: "success",
                  message:
                    "Embedded Trip Planner settings updated successfully",
                });
              },
              onError: (e) => {
                notificationStore.setNotificationFromError(e);
              },
            });
          }}
        />
      </div>
    </SettingsContainer>
  );
};
