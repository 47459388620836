import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Button,
  Popup,
  palette,
  TextInput,
  FlexContainer,
  NumberInput,
  PlusIcon,
  Text,
} from "@busie/ui-kit";
import { User } from "@busie/utils";
import { useAuthTokenWithAudience } from "@busie/core";
import { Destination } from "@busie/api";

import { paymentStore, qnbStore } from "~/settings/store";

import { percentageValidation } from "../helpers";

interface Props {
  onCreateSuccess: () => void;
}

export const CreateIntegrationBlock: React.FC<
  React.PropsWithChildren<Props>
> = ({ onCreateSuccess }) => {
  const { user } = useAuth0<User>();
  const paymentAuthToken = useAuthTokenWithAudience("payments");
  const qnbAuthToken = useAuthTokenWithAudience("quotesAndBookings");
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<{ name: string; revenue: number }>(
    { name: "", revenue: 10 }
  );

  React.useEffect(() => {
    if (paymentAuthToken && !paymentStore.authToken) {
      paymentStore.setAuthToken(paymentAuthToken);
      paymentStore.getIsAccountLinked(user?.org_id || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAuthToken]);

  React.useEffect(() => {
    if (qnbAuthToken) {
      qnbStore.setAuthToken(qnbAuthToken);
    }
  }, [qnbAuthToken]);

  const closePopup = () => {
    setIsOpen(false);
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const submitForm = async () => {
    const { revenue, name } = values;
    const revenueShare = await paymentStore.createRevenueShare(
      user?.org_id || "",
      revenue,
      name
    );

    if (!revenueShare) return;

    const destinationOrg = revenueShare.destinations.find(
      (d: Destination) => !!d.publicIntegrationKey
    );

    qnbStore.createIntegration(destinationOrg.organizationId, revenueShare._id);
    closePopup();
    onCreateSuccess && onCreateSuccess();
  };

  return (
    <>
      <Button
        onClick={togglePopup}
        startIcon={<PlusIcon color={palette.black.plus100} />}
      >
        Create
      </Button>
      <Popup isPopupOpened={isOpen} title="Create new integration">
        <FlexContainer
          w={366}
          px={5}
          pb={5}
          rowGap={1}
          direction="column"
          fullWidth
        >
          <TextInput
            label="Name"
            fullWidth
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
          <Text color={palette.red.main} weight={300}>
            Current Platform Fee:{" "}
            {paymentStore.account?.currentPlatformFee || 0}%
          </Text>
          <NumberInput
            label="Revenue share %"
            isAllowed={(value) =>
              percentageValidation(
                value,
                paymentStore.account?.currentPlatformFee
              )
            }
            suffix="%"
            value={values.revenue}
            onChange={(e) =>
              setValues({ ...values, revenue: Number(e.target.value) })
            }
            fullWidth
          />
          <FlexContainer justify="flex-end" pt={2} fullWidth columnGap={1}>
            <Button typestyle="secondary" onClick={closePopup}>
              Cancel
            </Button>
            <Button disabled={!values.name} onClick={submitForm} async>
              Create
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Popup>
    </>
  );
};
