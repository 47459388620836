import { useMutation } from "react-query";

import { createBooking, updateQuoteStatus } from "@busie/api";
import { QuoteAndBookingStatus } from "@busie/utils";
import { invalidateQnbQueries } from "~/QuotesAndBookingsPage/model";
import { useAuthTokenWithAudience } from "@busie/core";

export const useUpdateQuoteStatus = (
  id: string,
  status: QuoteAndBookingStatus
) => {
  const authToken = useAuthTokenWithAudience("quotesAndBookings");

  const result = useMutation(async (reason?: string) => {
    const result = await updateQuoteStatus(authToken, id, status, reason);

    if (status === QuoteAndBookingStatus.ACCEPTED)
      await createBooking(authToken, id);

    invalidateQnbQueries();

    return result;
  });

  return result;
};
