import { useMutation } from "react-query";

import { QuoteAndBookingStatus } from "@busie/utils";
import { invalidateQnbQueries } from "~/QuotesAndBookingsPage/model";
import { updateBookingStatus } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";

export const useUpdateBookingStatus = (
  id: string,
  status: QuoteAndBookingStatus
) => {
  const authToken = useAuthTokenWithAudience("quotesAndBookings");

  const result = useMutation(async () => {
    await updateBookingStatus(authToken, id, status);
    invalidateQnbQueries();
  });

  return result;
};
