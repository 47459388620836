import { useQuery } from "react-query";

import { fetchGroup } from "@busie/api";
import {
  CUSTOMERS_QUERY_KEY,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";

interface FetchGroupParams {
  id: string;
}

const GROUP_QUERY_KEY = Symbol();

export const useFetchGroup = ({ id }: FetchGroupParams) => {
  const customersAuthToken = useAuthTokenWithAudience("customers");

  const query = useQuery(
    [CUSTOMERS_QUERY_KEY, GROUP_QUERY_KEY, id],
    () => fetchGroup(customersAuthToken, id),
    {
      enabled: !!customersAuthToken,
      staleTime: STALE_TIMES.DEFAULT,
      keepPreviousData: true,
    }
  );

  return query;
};
