import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";

import { Loading, Button, palette, PlusIcon, H1, Hr } from "@busie/ui-kit";
import { DispatchLocation } from "@busie/utils";

import { SettingsContainer } from "~/settings/shared/ui";
import {
  deleteLocationStore,
  dispatchLocationStore,
  submitLocationStore,
} from "~/settings/store/dispatch";

import {
  NoElements,
  SubmitLocationPopup,
  DeleteLocationPopup,
  DispatchLocationsList,
} from "./ui";
import { useAuthTokenWithAudience } from "@busie/core";

export const Page = observer(() => {
  const { isLoading } = useAuth0();
  const dispatchLocationsAuthToken =
    useAuthTokenWithAudience("dispatchLocations");
  const getContent = () => {
    if (dispatchLocationStore.isNoLocations) {
      return <NoElements onAdd={submitLocationStore.openCreateModal} />;
    }

    return (
      <DispatchLocationsList
        onDelete={deleteLocationStore.openDeleteModal}
        locations={
          dispatchLocationStore.dispatchLocations as DispatchLocation[]
        }
        onEdit={submitLocationStore.openEditModal}
      />
    );
  };

  useEffect(() => {
    if (!dispatchLocationStore.authToken) {
      dispatchLocationStore.setAuthToken(dispatchLocationsAuthToken);
    }
  }, [dispatchLocationsAuthToken]);

  useEffect(() => {
    if (dispatchLocationStore.authToken) {
      dispatchLocationStore.getDispatchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchLocationStore.authToken]);

  if (dispatchLocationStore.isDataFetching || isLoading) return <Loading />;

  return (
    <SettingsContainer
      value="Dispatch Locations"
      controls={
        <Button
          onClick={submitLocationStore.openCreateModal}
          startIcon={<PlusIcon color={palette.black.plus100} />}
        >
          Add dispatch location
        </Button>
      }
    >
      <H1>Dispatch Locations</H1>
      <Hr />
      {getContent()}
      <DeleteLocationPopup />
      {submitLocationStore.isModalOpen && <SubmitLocationPopup />}
    </SettingsContainer>
  );
});
