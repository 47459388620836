import {
  RouteRequest,
  createRouteRequest,
  fetchChefBooking,
  fetchChefQuote,
  fetchQuotesIntegrations,
  fetchReservation,
  fetchVehicle,
  getDispatchLocation,
  getDispatchLocations,
  listChefBookings,
  listChefQuotes,
} from "@busie/api";
import { queryClient, useAuthTokenWithAudience } from "@busie/core";
import { useQueries, useQuery } from "react-query";
import { ITEMS_PER_PAGE } from "./constants";
import { QuoteAndBookingStatus, dayjsExt } from "@busie/utils";
import { Dayjs } from "dayjs";

const KEY = "CHEF_QUERY_KEY";
const QUOTE_KEY = "QUOTE";
const QUOTES_KEY = "QUOTES";
const BOOKINGS_KEY = "BOOKINGS";
const RESERVATIONS_KEY = "RESERVATIONS";
const VEHICLES_KEY = "VEHICLES";
const DISPATCH_LOCATION_KEY = "DISPATCH_LOCATION";
const DISPATCH_LOCATIONS_KEY = "DISPATCH_LOCATIONS";
const PATHFINDER_KEY = "PATHFINDER";
const DEFAULT_STALE_TIME = 30 * 1000; // 30 seconds

export interface ListQuotesQuery {
  status?: QuoteAndBookingStatus[];
  startDate?: [Dayjs | null, Dayjs | null];
  createdDate?: [Dayjs | null, Dayjs | null];
  dispatchLocations?: string[];
  assigneeId?: string | undefined | null;
  search?: string;
}

export interface SortQuotesQuery {
  [key: string]: "asc" | "desc";
}

export const invalidateQnbQueries = (): void => {
  queryClient.invalidateQueries(KEY);
};

export const useFetchQuote = (id: string, refetchInterval = 10 * 1000 * 60) => {
  const authToken = useAuthTokenWithAudience("chef");

  const result = useQuery(
    [KEY, QUOTE_KEY, id],
    () => fetchChefQuote(authToken, id as string),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !!id && !!authToken,
      refetchInterval,
    }
  );

  return result;
};

export const useListQuotes = (
  currentPage: number,
  sort?: SortQuotesQuery,
  query?: ListQuotesQuery
) => {
  const authToken = useAuthTokenWithAudience("chef");

  const result = useQuery(
    [KEY, QUOTES_KEY, currentPage, sort, query],
    () =>
      listChefQuotes(authToken, {
        limit: ITEMS_PER_PAGE,
        page: currentPage,
        sort: sort
          ? Object.keys(sort)
              .map((key) => `${key}:${sort[key]}`)
              .join(",")
          : undefined,
        status: query?.status?.join(","),
        startDate: query?.startDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        createdDate: query?.createdDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        dispatchLocations: query?.dispatchLocations?.join(","),
        assignee:
          query?.assigneeId === "ALL"
            ? undefined
            : query?.assigneeId === "UNASSIGNED"
            ? ""
            : query?.assigneeId,
        search: query?.search,
      }),
    {
      staleTime: DEFAULT_STALE_TIME,
      refetchInterval: 5 * 1000,
      enabled: !!authToken,
    }
  );

  const { data, isLoading, ...rest } = result;

  return {
    data: data ?? { total: 0, data: [] },
    isLoading: isLoading || !authToken,
    ...rest,
  };
};

export const useListBookings = (
  currentPage: number,
  sort?: SortQuotesQuery,
  query?: ListQuotesQuery
) => {
  const authToken = useAuthTokenWithAudience("chef");

  const { data, isLoading, ...rest } = useQuery(
    [KEY, BOOKINGS_KEY, currentPage, sort, query],
    () =>
      listChefBookings(authToken, {
        limit: ITEMS_PER_PAGE,
        page: currentPage,
        sort: sort
          ? Object.keys(sort)
              .map((key) => `${key}:${sort[key]}`)
              .join(",")
          : undefined,
        status: query?.status?.join(","),
        startDate: query?.startDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        createdDate: query?.createdDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        dispatchLocations: query?.dispatchLocations?.join(","),
        assignee:
          query?.assigneeId === "ALL"
            ? undefined
            : query?.assigneeId === "UNASSIGNED"
            ? ""
            : query?.assigneeId,
        search: query?.search,
      }),
    {
      staleTime: DEFAULT_STALE_TIME,
      refetchInterval: 5 * 1000,
      enabled: !!authToken,
    }
  );

  return {
    data: data ?? { total: 0, data: [] },
    isLoading: isLoading || !authToken,
    ...rest,
  };
};

export const useFetchBooking = (
  id: string,
  refetchInterval = 10 * 1000 * 60
) => {
  const authToken = useAuthTokenWithAudience("chef");

  const result = useQuery(
    [KEY, BOOKINGS_KEY, id],
    () => fetchChefBooking(authToken, id as string),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !!id && !!authToken,
      refetchInterval,
    }
  );

  return result;
};

export const useExperienceReservations = (reservationIds: string[]) => {
  const authToken = useAuthTokenWithAudience("vehicles");
  const result = useQueries(
    reservationIds.map((reservationId) => {
      return {
        queryKey: [KEY, RESERVATIONS_KEY, reservationId],
        queryFn: () => fetchReservation(authToken, reservationId),
        enabled: !!authToken,
        staleTime: DEFAULT_STALE_TIME,
      };
    })
  );

  return result;
};

export const useExperienceVehicles = (vehicleIds: string[]) => {
  const authToken = useAuthTokenWithAudience("vehicles");

  const result = useQueries(
    vehicleIds.map((vehicleId) => ({
      queryKey: [KEY, VEHICLES_KEY, vehicleId],
      queryFn: () => fetchVehicle(authToken, vehicleId),
      enabled: !!authToken,
      staleTime: DEFAULT_STALE_TIME,
    }))
  );

  return result;
};

export const useExperienceDispatchLocation = (dispatchLocationId: string) => {
  const authToken = useAuthTokenWithAudience("dispatchLocations");

  const result = useQuery(
    [KEY, DISPATCH_LOCATION_KEY, dispatchLocationId],
    () => getDispatchLocation(dispatchLocationId, authToken),
    {
      enabled: !!authToken && !!dispatchLocationId,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useFetchQuoteIntegrations = () => {
  const authToken = useAuthTokenWithAudience("quotesAndBookings");

  const result = useQuery(
    [KEY, "INTEGRATIONS"],
    () => fetchQuotesIntegrations(authToken),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !!authToken,
    }
  );

  return result;
};

export const useDispatchLocations = () => {
  const authToken = useAuthTokenWithAudience("dispatchLocations");
  const { data, ...result } = useQuery(
    [KEY, DISPATCH_LOCATIONS_KEY],
    () => getDispatchLocations(authToken),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !!authToken,
    }
  );

  return { data: data ?? [], ...result };
};

export const useTripRoute = (data: RouteRequest) => {
  const authToken = useAuthTokenWithAudience("pathfinder");

  return useQuery(
    [KEY, PATHFINDER_KEY, data],
    () => createRouteRequest(authToken, data),
    {
      // 10 mins is probably good. We don't need to fetch this often - shouldn't really change.
      staleTime: DEFAULT_STALE_TIME * 2,
      enabled: !!authToken,
    }
  );
};
