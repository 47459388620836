import { sendQuote } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";
import { useMutation } from "react-query";

export const useSendQuote = (id: string) => {
  const authToken = useAuthTokenWithAudience("quotesAndBookings");

  const result = useMutation(async () => {
    await sendQuote(authToken, id);
  });

  return result;
};
