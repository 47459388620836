import React, { FC } from "react";
import { observer } from "mobx-react";
import store from "~/vehicles/store";

import { Button, FlexContainer, Loading, Popup } from "@busie/ui-kit";
import { Reservation } from "@busie/utils";

import VehiclesNoContent from "./components/NoContentBlock";
import PageHeader from "./components/PageHeader";
import VehiclesFilter from "./components/VehiclesFilter";
import CalendarMonthView from "./CalendarView/CalendarMonthView";
import CalendarWeekView from "./CalendarView/CalendarWeekView";
import ListView from "./ListView";
import CollapsedView from "./CollapsedView";
import ListViewHeader from "./ListView/ListViewHeader";
import CollapsedViewHeader from "./CollapsedView/CollapsedViewHeader";
import VehiclesPageStyled from "./styled/VehiclesPageStyled";
import ReservationForm from "./components/ReservationForm";
import { ReservationFormFooterStyled } from "./CalendarView/styled/ReservationFormPopup.styled";
import ReservationDetailsDrawer from "./CalendarView/components/ReservationDetailsDrawer";
import EditReservationForm from "./CalendarView/components/EditReservationForm";
import CalendarDayView from "./CalendarView/CalendarDayView";
import { useAuthTokenWithAudience } from "@busie/core";

type RDContext = ((value: Reservation) => void) | null;
export const ReservationDrawerContext = React.createContext<RDContext>(null);

const Vehicles: FC<React.PropsWithChildren<unknown>> = observer(() => {
  const authToken = useAuthTokenWithAudience("vehicles");

  const submitReservationForm = async () => {
    await store.createReservation();
    await store.fetchReservations();
    store.closeReservationPopup();
  };

  const [openedReservation, setOpenedReservation] =
    React.useState<Reservation | null>(null);

  const openEditForm = () => {
    if (!openedReservation) return;
    store.setEditingReservationFormData(openedReservation);
    setOpenedReservation(null);
    setEditFormOpen(true);
  };

  const [editFormOpen, setEditFormOpen] = React.useState(false);

  const onEditSubmit = async () => {
    await store.editReservation();
    setEditFormOpen(false);
    store.fetchReservations();
  };

  const onUpdate = async (reservation: Reservation) => {
    setOpenedReservation(reservation);
    store.fetchReservations();
  };

  React.useEffect(() => {
    if (!authToken) return;

    store.setAuthToken(authToken);

    if (!store.vehicles.length) {
      store.setVehicles();
    }

    store.fetchReservations();

    if (!store.vehicleTypes.length) {
      store.fetchVehicleTypes(authToken);
    }

    store.fetchAvailabilityPercentage();
  }, [authToken]);

  if (store.isFetching) {
    return <Loading />;
  }
  if (store.vehicles.length === 0 && store.isFetched) {
    return <VehiclesNoContent />;
  }

  return (
    <VehiclesPageStyled>
      <PageHeader />
      <FlexContainer bordered direction="column" noPadding>
        <VehiclesFilter />
        {store.viewMode === "list" && <ListViewHeader />}
        {store.viewMode === "collapsed" && <CollapsedViewHeader />}
      </FlexContainer>

      <ReservationDrawerContext.Provider value={setOpenedReservation}>
        {store.reservationsAreFetched ? (
          <>
            {store.viewMode === "calendar" &&
              store.calendarViewPeriod === "month" && <CalendarMonthView />}
            {store.viewMode === "calendar" &&
              store.calendarViewPeriod === "week" && <CalendarWeekView />}
            {store.viewMode === "calendar" &&
              store.calendarViewPeriod === "day" && <CalendarDayView />}
            {store.viewMode === "list" && <ListView />}
            {store.viewMode === "collapsed" && <CollapsedView />}
          </>
        ) : (
          <Loading />
        )}
      </ReservationDrawerContext.Provider>

      <Popup
        isPopupOpened={store.reservationPopup.isOpen}
        title="Vehicle reservation"
        onClose={store.closeReservationPopup}
      >
        <>
          <ReservationForm
            vehicleTypes={store.reservationPopup.vehicleTypes}
            vehicles={store.reservationPopup.vehicles}
          />
          <ReservationFormFooterStyled justify="flex-end" columnGap={2}>
            <Button typestyle="secondary" onClick={store.closeReservationPopup}>
              Cancel
            </Button>
            <Button
              typestyle="primary"
              onClick={submitReservationForm}
              disabled={!store.isReservationFormValid}
            >
              Reserve
            </Button>
          </ReservationFormFooterStyled>
        </>
      </Popup>
      <ReservationDetailsDrawer
        reservation={openedReservation}
        onEdit={openEditForm}
        onReservationEdited={onUpdate}
        onClose={() => setOpenedReservation(null)}
      />
      <EditReservationForm
        isOpen={editFormOpen}
        onClose={() => setEditFormOpen(false)}
        onSubmit={onEditSubmit}
      />
    </VehiclesPageStyled>
  );
});

export default Vehicles;
