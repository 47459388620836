import React from "react";
import { observer } from "mobx-react-lite";

import { deleteRecipe } from "@busie/api";

import {
  Text,
  Title,
  palette,
  Button,
  Popup,
  FlexContainer,
} from "@busie/ui-kit";
import { notificationStore } from "@busie/features";
import { useAuthTokenWithAudience } from "@busie/core";
//import { useQueryClient } from "react-query";

interface Props {
  id: string;
  isPopupOpened: boolean;
  onClose: (deleted?: boolean) => void;
}

const DeleteRecipePopup: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ isPopupOpened, onClose, id }: Props) => {
    //const queryClient = useQueryClient();
    const authToken = useAuthTokenWithAudience("rates");
    const handlerOnDelete = async () => {
      try {
        await deleteRecipe(authToken, id);
        //queryClient.invalidateQueries("rates");
        notificationStore.setSuccessNotification(
          "Success",
          "The recipe was deleted"
        );
      } finally {
        onClose(true);
      }
    };
    return (
      <Popup
        title={<Title>Delete recipe?</Title>}
        isPopupOpened={isPopupOpened}
        onClose={onClose}
      >
        <FlexContainer w={480} px={5} pb={5} rowGap={3} direction="column">
          <Text color={palette?.black?.plus10}>
            Are you sure you want to delete this recipe? All ingredients will be
            lost.
          </Text>
          <FlexContainer justify="flex-end" columnGap={2} fullWidth>
            <Button typestyle="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button typestyle="accent" async onClick={handlerOnDelete}>
              Delete
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Popup>
    );
  }
);

export default DeleteRecipePopup;
