import {
  FlexContainer,
  InfoIcon,
  NumberInput,
  NumberInputEvent,
  TextInput,
  TextInputEvent,
} from "@busie/ui-kit";
import { Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import store from "../../store";
import { CustomerFormFieldsKeys } from "../../store/customerForm/store.types";
import TooltipIconStyled from "../styled/TooltipIconStyled";
import { GroupSelectAsync } from "./components/GroupsSelectAsync";

import CustomerDataFieldsStyled from "./styled/CustomerDataFieldsStyled";

const CustomerDataFields: React.FC = observer(() => {
  const customerForm = store.customerForm;

  const handlerOnChange = (
    e: TextInputEvent | NumberInputEvent,
    name: CustomerFormFieldsKeys
  ) => customerForm.setCustomerFormValue(name, e.target.value);

  return (
    <CustomerDataFieldsStyled>
      <TextInput
        required
        label="Full Name"
        onChange={(e) => handlerOnChange(e, "name")}
        name="fullName"
        placeholder="Enter full name"
        value={customerForm.fields.name}
        errorMessage={customerForm.validation.errors.name}
      />
      <FlexContainer columnGap={2} align="center" w="auto" h={90}>
        <GroupSelectAsync handlerOnChange={handlerOnChange} />
      </FlexContainer>
      <TextInput
        required
        label="Email"
        onChange={(e) => handlerOnChange(e, "email")}
        name="email"
        placeholder="Enter email"
        value={customerForm.fields.email}
        errorMessage={customerForm.validation.errors.email}
      />
      <FlexContainer columnGap={2} align="center" w="240px" h={90}>
        <NumberInput
          required
          label="Phone number"
          onChange={(e) => handlerOnChange(e, "phoneNumber")}
          name="phoneNumber"
          placeholder="+1 (000) 000-0000"
          isPhoneField={true}
          value={customerForm.fields.phoneNumber}
          errorMessage={customerForm.validation.errors.phoneNumber}
          tooltip="hello"
        />
        <Tooltip
          title={`Please include the country code of your phone number (i.e. '1' for US).
          Do not include special characters (i.e. +()-# etc.).
          If you find your phone number is not supported, please leave a note in the 'Additional Info' section on the next step.
          `}
          placement="top"
        >
          <TooltipIconStyled>
            <InfoIcon size={24} />
          </TooltipIconStyled>
        </Tooltip>
      </FlexContainer>
    </CustomerDataFieldsStyled>
  );
});

export default CustomerDataFields;
