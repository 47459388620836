import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  ErrorMessage,
  FormContainer,
  InputLabel,
  palette,
  TextInput,
} from "@busie/ui-kit";

import {
  useCustomersOrganization,
  useDebouncedCallback,
  useGroupsSearch,
  useUserContext,
} from "@busie/core";
import { Customer } from "@busie/utils";
import PhoneInput, {
  DefaultInputComponentProps,
} from "react-phone-number-input/react-hook-form-input";
import { validationSchema } from "./validationSchema";

interface OrganizationOption {
  label: string;
  value: string;
}

interface FormValues {
  name: string;
  email: string;
  phoneNumber: string;
  organization: OrganizationOption;
}

interface Props {
  customer?: Customer;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  organizationId?: string;
}

export const CustomerForm: React.FC<React.PropsWithChildren<Props>> = ({
  customer,
  onSubmit,
  onCancel,
  organizationId,
}) => {
  const { customersAuthToken } = useUserContext();
  // TODO: [BUSIE-1460] Disable new group creation/selection if the organizationId is passed.
  const mode = customer ? "update" : "create";

  const [searchQuery, setSearchQuery] = useState("");

  // Only fetch when searchQuery has value
  const { data: organizationsData } = useGroupsSearch(
    customersAuthToken,
    searchQuery,
    {
      enabled: searchQuery.length > 0, // Only run query when search has value
    }
  );

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
  }, 700);

  const filter = createFilterOptions<OrganizationOption>();

  const customerOrganization = useCustomersOrganization(
    customer?.groupIds[0] || organizationId || ""
  );

  const organizationInitialValue = useMemo(
    () =>
      customerOrganization
        ? {
            label: customerOrganization.name,
            value: customerOrganization.id,
          }
        : {
            label: "",
            value: "",
          },
    [customerOrganization]
  );

  const defaultValues: FormValues = {
    name: customer?.name || "",
    email: customer?.email || "",
    phoneNumber: customer?.phoneNumber || "",
    organization: organizationInitialValue,
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("organization", organizationInitialValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [organizationInitialValue, setValue]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const organizationOptions = useMemo(() => {
    const options = organizationsData?.results.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    if (!options) return [];

    const newOrgs: OrganizationOption[] = options;

    options.forEach((norg) => {
      const matches = options.filter((o) => o.label === norg.label);
      const hasDuplicates = matches.length > 1;

      if (hasDuplicates) {
        matches.forEach((match, matchIndex) => {
          const index = options.findIndex((x) => x.value === match.value);

          if (matchIndex > 0) {
            newOrgs[index].label = `${newOrgs[index].label} (${matchIndex})`;
          }
        });
      }
    });

    return newOrgs;
  }, [organizationsData?.results]);

  return (
    <FormContainer title={mode === "create" ? "New customer" : "Edit customer"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ height: "90px" }}>
          <InputLabel>Organization</InputLabel>
          <Controller
            name="organization"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={organizationOptions}
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // TODO:
                getOptionLabel={(option) =>
                  (option as { label: string; value: string }).label || ""
                }
                onInputChange={(event, newInputValue) => {
                  // This will fire on every keystroke
                  debouncedSearch(newInputValue);
                }}
                onChange={(_, value) => {
                  if (value === null) return;
                  if (typeof value === "string") {
                    setValue(
                      "organization",
                      { label: value, value },
                      { shouldDirty: true, shouldValidate: true }
                    );
                  } else {
                    setValue("organization", value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      label: `Create "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Find or create new"
                    sx={{
                      ".MuiOutlinedInput-root": {
                        height: "40px",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.black?.plus10}`,
                      },
                    }}
                  />
                )}
              />
            )}
          />
          <ErrorMessage style={{ marginBottom: "13px" }}>
            {errors.organization?.value?.message}
          </ErrorMessage>
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Full Name"
              placeholder="e.g. Jim Figo"
              errorMessage={errors.name?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Email"
              errorMessage={errors.email?.message}
            />
          )}
        />

        <PhoneInput
          defaultCountry="US"
          control={control}
          name="phoneNumber"
          label="Phone Number"
          rules={{ required: true }}
          inputComponent={
            TextInput as DefaultInputComponentProps["inputComponent"]
          }
          errorMessage={errors.phoneNumber?.message}
          required
        />

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            type="button"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid || isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
