import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  useTripLegVehiclePricing,
  useTripLegVehicles,
  useRecipe,
  useAuthTokenWithAudience,
} from "@busie/core";
import {
  FlexContainer,
  ScrollableFlexContainer,
  Divider,
  H2,
  Popup,
  Select,
  Text,
  theme,
  Loading,
  CommonTable,
  ArrowRight,
  palette,
} from "@busie/ui-kit";
import {
  centsToDollars,
  currencyFormatter,
  IngredientType,
  INGREDIENT_TYPE_LABELS,
  TripLeg,
  Vehicle,
} from "@busie/utils";

interface Props {
  tripLeg: TripLeg;
  isOpen: boolean;
  dispatchLocationName: string;
  isLiveLeg: boolean;
  onClose: () => void;
}

export const LegPriceBreakdown: React.FC<React.PropsWithChildren<Props>> = ({
  tripLeg,
  isOpen,
  dispatchLocationName,
  isLiveLeg,
  onClose,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState<string>("");

  useEffect(() => {
    setSelectedVehicle(tripLeg._legPrice[0].vehicleId);
  }, [tripLeg]);

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const vehicles = useTripLegVehicles(tripLeg);
  const vehiclePricing = useTripLegVehiclePricing(tripLeg, selectedVehicle);

  const getIngredientType = (type: IngredientType): string =>
    INGREDIENT_TYPE_LABELS[type];

  const getFriendlyAddress = (address: string) => {
    return address
      .split(", ")
      .slice(0, 3)
      .join(", ")
      .replace(/ \d{5}/, "");
  };

  const legAddressFallbackText = isLiveLeg
    ? "Unknown Leg Address"
    : dispatchLocationName;

  const ratesAuthToken = useAuthTokenWithAudience("rates");
  const { data: recipe } = useRecipe(
    ratesAuthToken,
    vehiclePricing.legPriceData?.recipeId
  );

  if (!isOpen || !tripLeg) return null;

  return (
    <Popup
      isPopupOpened={isOpen}
      title="Leg Price Breakdown"
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: isMobile ? "100vw" : "600px",
          maxWidth: isMobile ? "100vw" : "600px",
          margin: isMobile ? 0 : "initial",
        },
      }}
      // @ts-expect-error no error
      style={
        !isMobile
          ? undefined
          : {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
            }
      }
    >
      <FlexContainer direction="column" rowGap={1} px={2}>
        {!!tripLeg && (
          <FlexContainer justify="space-between" columnGap={3} pl={1}>
            <Text>
              {getFriendlyAddress(
                tripLeg._startLocation.address || legAddressFallbackText
              )}
            </Text>
            <ArrowRight color={palette.red.main} size={16} />
            <Text>
              {getFriendlyAddress(
                tripLeg._destinationLocation.address || legAddressFallbackText
              )}
            </Text>
          </FlexContainer>
        )}
        <ScrollableFlexContainer direction="column" rowGap={1} fullWidth pb={2}>
          <FlexContainer justify="space-between" fullWidth>
            <Select
              label="Vehicle"
              onChange={(e) => setSelectedVehicle(e.target.value)}
              value={selectedVehicle}
              items={vehicles.map((vehicle: Vehicle) => ({
                value: vehicle.id,
                name: vehicle.licensePlate,
              }))}
              fullWidth
            />
          </FlexContainer>
          <FlexContainer justify="space-between" fullWidth>
            <H2 weight={600}>Subtotal</H2>
            <H2 weight={600}>
              {currencyFormatter(centsToDollars(vehiclePricing.subtotal))}
            </H2>
          </FlexContainer>
          <FlexContainer justify="space-between" fullWidth>
            <Text weight={500}>Recipe</Text>
            {(!!recipe && (
              <Text weight={500}>
                <Link
                  to={`/rates?recipeId=${recipe.id}`}
                  style={{ textDecoration: "none", margin: "0 0 0 4px" }}
                >
                  {recipe.name}
                </Link>
              </Text>
            )) || <Loading />}
          </FlexContainer>
          <Divider margin={isMobile ? 5 : 0} />
          <FlexContainer>
            <H2 weight={600}>Ingredient Breakdown</H2>
          </FlexContainer>
          {vehiclePricing.legPriceData && (
            <CommonTable
              // best way i could think of to create even spacing between the columns
              // with current table implementation
              columnNames={[
                { id: "name", name: "Name", width: "295px" },
                { id: "type", name: "Type", width: "295px" },
                { id: "price", name: "Price", width: "10px" },
              ]}
              data={vehiclePricing.legPriceData.priceBreakdown.map(
                ({ ingredientName, ingredientType, ingredientPrice }) => ({
                  Name: ingredientName,
                  Type: getIngredientType(ingredientType as IngredientType),
                  Price: currencyFormatter(centsToDollars(ingredientPrice)),
                })
              )}
            />
          )}
        </ScrollableFlexContainer>
      </FlexContainer>
    </Popup>
  );
};
