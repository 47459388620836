import { useUserContext } from "@busie/core";
import { Loading } from "@busie/ui-kit";
import { useSigmaIframe } from "@busie/utils";
import { FC, PropsWithChildren } from "react";
import { useEmbeddedAnalytics } from "./model";
import { AnalyticsPageNoContent } from "../../entity";

/**
 * Component that renders an analytics dashboard inside an iframe.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.dashboard - The identifier for the dashboard to be displayed.
 * @returns {JSX.Element} The rendered iframe or a loading/no content component based on the state.
 *
 * @example
 * <AnalyticsIFrame dashboard="dashboardId" />
 *
 * @remarks
 * This component uses the `useUserContext` hook to retrieve the authentication token,
 * and the `useEmbeddedAnalytics` hook to fetch the signed URL for the iframe source.
 * It also uses the `useSigmaIframe` hook to manage the iframe reference and handle errors.
 */
export const Feature: FC<PropsWithChildren<{ dashboard: string }>> = ({
  dashboard,
}) => {
  const { embeddedAnalyticsAuthToken: authToken } = useUserContext();

  const { isLoading, embedUrl: signedSrc } = useEmbeddedAnalytics({
    authToken,
    dashboard,
  });

  const { iframeRef, error: iFrameError } = useSigmaIframe();

  // only show loading spinner on initial data fetch. `isFetching` is true when the query is refetched as well as on initial load.
  if (isLoading) {
    return <Loading />;
  }

  if (!signedSrc || !iframeRef || iFrameError) {
    return <AnalyticsPageNoContent />;
  }

  return (
    <iframe
      src={signedSrc}
      ref={iframeRef}
      width={"100%"}
      height={"100%"}
      title={`Analytics ${dashboard} page`}
      style={{ border: "none" }}
    />
  );
};
