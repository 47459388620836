import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { H1, H2, H3, Text, Button, Loading, Hr, palette } from "@busie/ui-kit";
import {
  isOrganizationSettingsAllowed,
  User,
  currencyFormatter,
  centsToDollars,
} from "@busie/utils";
import { Account } from "@busie/api";

import { paymentStore } from "~/settings/store";
import { MessageType } from "~/settings/store/constants";
import { SettingsContainer } from "~/settings/shared/ui";

import { StatusModal } from "./ui";
import { useAuthTokenWithAudience } from "@busie/core";

export const Page: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const { user, isLoading } = useAuth0<User>();
  const history = useHistory();

  const paymentAuthToken = useAuthTokenWithAudience("payments");

  useEffect(() => {
    const url = new URL(window.location.toString());
    const status = url.searchParams.get("status");

    if (status) paymentStore.setNotification(status as MessageType);
  }, []);

  useEffect(() => {
    if (isLoading || !user) return;

    if (!isOrganizationSettingsAllowed(user)) history.goBack();
  }, [history, isLoading, user]);

  useEffect(() => {
    if (!paymentStore.authToken) {
      paymentStore.setAuthToken(paymentAuthToken);
    }
  }, [paymentAuthToken]);

  const {
    notification,
    clearNotification,
    account,
    isAccountLinked,
    linkUserAccount,
    dashboard,
    isLinkButtonDisabled,
    isLinkButtonLoading,
    getContinueOnboardingUrl,
  } = paymentStore;

  useEffect(() => {
    if (!user || !paymentStore.authToken) return;

    paymentStore.getIsAccountLinked(user.org_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, paymentStore.authToken]);

  useEffect(() => {
    if (!isAccountLinked || !paymentStore.authToken) return;

    paymentStore.getStripeDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountLinked, paymentStore.authToken]);

  if (isLoading) return <Loading />;
  return (
    <SettingsContainer value="Payments">
      {notification && (
        <StatusModal
          isOpen={!!notification}
          notification={notification}
          onClose={clearNotification}
        />
      )}
      <H1>Payments</H1>
      <Hr />
      <H2>Stripe</H2>
      <Text>
        Connect with Stripe to start accepting payments <br /> through the
        platform. Many features will not be <br /> available until your
        organization is connected with <br /> Stripe
      </Text>
      {account ? (
        isAccountLinked ? (
          <div className="dashboard-link">
            <H3>Monthly Payout</H3>
            <H2>
              {currencyFormatter(centsToDollars(dashboard?.monthlyPayout || 0))}
            </H2>
            <Button
              typestyle="primary"
              size="small"
              onClick={() =>
                window.open(dashboard?.dashboardUrl as string, "_blank")
              }
            >
              View Dashboard
            </Button>
          </div>
        ) : (
          <div className="dashboard-link">
            <H3>Stripe Onboarding Incomplete</H3>
            <H3 weight={500} color={palette?.red?.minus5}>
              You have not completed onboarding. <br />
              Please continue by clicking the button below.
            </H3>
            <Button
              typestyle="primary"
              size="small"
              onClick={() => getContinueOnboardingUrl(account as Account)}
              disabled={isLinkButtonDisabled}
              loading={isLinkButtonLoading}
            >
              Continue Onboarding
            </Button>
          </div>
        )
      ) : (
        <div className="dashboard-link">
          <H3>Connect Stripe</H3>
          <H3 weight={500}>
            Click the button below to set up your account with Stripe and
            automatically receive payouts for your bookings.
          </H3>
          <Button
            typestyle="primary"
            size="small"
            onClick={() => linkUserAccount((user as User).org_id)}
            disabled={isLinkButtonDisabled}
            loading={isLinkButtonLoading}
          >
            Connect Stripe
          </Button>
        </div>
      )}
    </SettingsContainer>
  );
});
