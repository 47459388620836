import { QueryKey, useQuery } from "react-query";

import { getInvoiceById, listInvoices, ListInvoicesParams } from "@busie/api";
import { queryClient, useAuthTokenWithAudience } from "@busie/core";

export const PAYMENTS_QUERY_KEY: QueryKey = "PAYMENTS";
export const DEFAULT_STALE_TIME = 5 * 1000; // 5 seconds -- Stale time only applies to queries that are not currently being watched. It does not mean that the query will be refetched after 5 seconds.

export const invalidatePaymentQueries = () => {
  queryClient.invalidateQueries(PAYMENTS_QUERY_KEY);
};

export const useListInvoices = (params: ListInvoicesParams = {}) => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [PAYMENTS_QUERY_KEY, "invoices", params ? params : undefined],
    () => listInvoices(paymentsAuthToken, params),
    {
      enabled: !!paymentsAuthToken,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useGetInvoiceById = (invoiceId?: string) => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [PAYMENTS_QUERY_KEY, "invoices", invoiceId],
    () => getInvoiceById(paymentsAuthToken, invoiceId as string),
    {
      enabled: !!paymentsAuthToken && !!invoiceId,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};
