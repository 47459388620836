import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { styled } from "@mui/system";

export const TabPanel = styled(BaseTabPanel)(() => {
  return {
    height: "100%",
    width: "100%",
    flex: 1,
    marginTop: "16px",
  };
});
