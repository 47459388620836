import { useAuth0 } from "@auth0/auth0-react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React from "react";
import { useHistory } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import { VehiclesRoutes } from "~/vehicles/VehiclesRoutes";
import RateMenuPage from "~/rates/RateMenuPage/RateMenuPage";
import { ProtectedRoute, useAuthTokenWithAudience, usePlan } from "@busie/core";
import { NotificationContainer, notificationStore } from "@busie/features";
import { HeaderItem, Layout } from "@busie/ui-kit";
import { isOrganizationSettingsAllowed, User } from "@busie/utils";

import { AnalyticsPage } from "~/AnalyticsPage/";
import { Page as ContractsPage } from "~/contracts";
import { CustomersPage } from "~/CustomersPage";
import { DriversPage } from "~/drivers";
import { Page as ProfilePage } from "~/profile";
import {
  AddQuotePage,
  ArchivedBookingsPage,
  ArchivedQuotesPage,
  BookingsPage,
  QuotesPage,
} from "~/QuotesAndBookingsPage";
import { Page as UpgradePage } from "~/upgrade";

import {
  DispatchLocationsSettings,
  DriversSettings,
  EmbeddedTripPlannerSettings,
  PaymentsSettings,
  Plan,
  QuoteIntegrationSettings,
  QuotesSettings,
  TripSettings,
} from "~/settings";
import { paymentStore } from "~/settings/store";

import { EmbeddedFormContainer } from "./EmbeddedFormContainer";

const headerLinks: HeaderItem[] = [
  {
    name: "Dispatch & Operations",
    to: "",
    children: [
      { name: "My Vehicles", path: "/vehicles/my-vehicles" },
      { name: "Reservations", path: "/reservations" },
      { name: "Drivers", path: "/drivers" },
    ],
  },
  {
    name: "Rate Menu",
    to: "/rates",
  },
  {
    name: "Customers",
    to: "/customers",
  },
  // {
  //   name: "Analytics",
  //   to: "/analytics",
  // },
];

export const InternalPages: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory();
  const [onboardingCompleted, setOnboardingCompleted] = React.useState<
    boolean | null
  >(null);
  const paymentAuthToken = useAuthTokenWithAudience("payments");
  const { user } = useAuth0<User>();
  const ldClient = useLDClient();

  const { rolloutContracts, rolloutEmbeddedAnalytics } = useFlags<{
    rolloutContracts: boolean;
    rolloutEmbeddedAnalytics: boolean;
  }>();

  const { data: plan } = usePlan(user?.org_id, ["data"]);

  React.useEffect(() => {
    if (user) {
      ldClient?.identify({
        kind: "multi",
        user: {
          key: user.sub,
          name: user.name,
          email: user.email,
          organizationId: user.org_id,
          roles: user["https://getbusie.com/roles"].join(";"),
        },
        organization: {
          key: user["https://getbusie.com/organization"].id,
          name: user["https://getbusie.com/organization"].display_name,
          size: user["https://getbusie.com/organization"].metadata.size,
          orgType: user["https://getbusie.com/organization"].metadata.org_type,
        },
      });
    }
  }, [user, ldClient]);

  const redirectNonOnboardedUser = (hasCompletedOnboarding: boolean | null) => {
    if (user && hasCompletedOnboarding !== null && !hasCompletedOnboarding) {
      if (isOrganizationSettingsAllowed(user)) {
        if (
          !history.location.pathname.startsWith("/organization-settings/") &&
          !history.location.pathname.startsWith("/profile")
        ) {
          history.replace("/organization-settings/payments");
        }
      } else {
        if (history.location.pathname !== "/profile") {
          history.replace("/profile");
        }
      }

      notificationStore.setErrorNotification(
        "Onboarding pending",
        "Your organization is not yet onboarded, please check again later"
      );
    }
  };

  // TODO: [BUSIE-1573] Validate onboarding status using the Plans API, instead of Payment Accounts
  React.useEffect(() => {
    const validateOnboarding = async () => {
      if (paymentAuthToken && user?.org_id && !paymentStore.isAccountLinked) {
        paymentStore.setAuthToken(paymentAuthToken);
        const onbCompleted = await paymentStore.getIsAccountLinked(user.org_id);
        setOnboardingCompleted(onbCompleted);

        redirectNonOnboardedUser(onbCompleted);
      }
    };

    validateOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAuthToken, user]);

  React.useEffect(() => {
    history.listen(() => {
      redirectNonOnboardedUser(onboardingCompleted);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, onboardingCompleted]);

  // TODO:
  // Users should first connect stripe
  // then create a dispatch location
  // then rates (standard rate for Organization Cookbook)
  // then vehicles
  return (
    <Layout
      headerLinks={[
        {
          name: "Sales",
          to: "",
          children: [
            { name: "Quotes & Bookings", path: "/quotes-and-bookings" },
            ...(rolloutContracts
              ? [{ name: "Contracts", path: "/contracts" }]
              : []),
          ],
        },
        ...headerLinks,
        ...(rolloutEmbeddedAnalytics
          ? [
              {
                name: "Analytics",
                to: "/analytics",
              },
            ]
          : []),
      ]}
    >
      <Switch>
        <ProtectedRoute path="/vehicles" component={VehiclesRoutes} />
        <ProtectedRoute path="/reservations" component={VehiclesRoutes} />
        <ProtectedRoute strict path="/rates" component={RateMenuPage} />
        <ProtectedRoute
          strict
          exact
          path="/quotes-and-bookings/quotes"
          component={QuotesPage}
        />
        <ProtectedRoute
          strict
          exact
          path="/quotes-and-bookings/bookings"
          component={BookingsPage}
        />
        <ProtectedRoute
          strict
          exact
          path="/quotes-and-bookings/archived/quotes"
          component={ArchivedQuotesPage}
        />
        <ProtectedRoute
          strict
          exact
          path="/quotes-and-bookings/archived/bookings"
          component={ArchivedBookingsPage}
        />
        <ProtectedRoute
          strict
          exact
          path="/quotes-and-bookings/add-quote"
          component={AddQuotePage}
        />
        <Route path="/quotes-and-bookings" exact>
          <Redirect
            to={{
              pathname: "/quotes-and-bookings/quotes",
            }}
          />
        </Route>
        <ProtectedRoute strict path="/analytics" component={AnalyticsPage} />
        <ProtectedRoute strict path="/profile" component={ProfilePage} />
        <ProtectedRoute path="/customers" component={CustomersPage} />
        <ProtectedRoute path="/drivers" component={DriversPage} />

        {rolloutContracts && (
          <ProtectedRoute
            strict
            exact
            path="/contracts"
            component={
              !plan || plan?.subscriptionType === "FREEMIUM"
                ? UpgradePage
                : ContractsPage
            }
          />
        )}

        <ProtectedRoute
          strict
          exact
          path="/organization-settings/dispatch"
          component={DispatchLocationsSettings}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/payments"
          component={PaymentsSettings}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/integration"
          component={QuoteIntegrationSettings}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/qnb"
          component={QuotesSettings}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/trip"
          component={TripSettings}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/embedded-trip-planner"
          component={EmbeddedTripPlannerSettings}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/plan"
          component={Plan}
        />
        <ProtectedRoute
          strict
          exact
          path="/organization-settings/drivers"
          component={DriversSettings}
        />
        <Route path="/embed" component={EmbeddedFormContainer} />
        <Route path="/">
          <Redirect
            to={{
              pathname: "/quotes-and-bookings/quotes",
              search: window.location.search,
            }}
          />
        </Route>
      </Switch>
      <NotificationContainer />
    </Layout>
  );
};
