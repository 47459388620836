import { listChefContracts } from "@busie/api";
import { queryClient, useAuthTokenWithAudience } from "@busie/core";
import { useQuery } from "react-query";
import { ITEMS_PER_PAGE } from "./constants";
import { dayjsExt } from "@busie/utils";
import { ListContractsQuery, SortContractsQuery } from "./interface";

const KEY = "CONTRACTS";
const DEFAULT_STALE_TIME = 30 * 1000;

export const invalidateQueries = () => {
  queryClient.invalidateQueries(KEY);
};

export const useListChefContracts = (
  currentPage: number,
  sort?: SortContractsQuery,
  query?: ListContractsQuery
) => {
  const token = useAuthTokenWithAudience("chef");

  const result = useQuery(
    [KEY, currentPage, sort, query],
    () =>
      listChefContracts(token, {
        limit: ITEMS_PER_PAGE,
        page: currentPage,
        sort: sort
          ? Object.keys(sort)
              .map((key) => `${key}:${sort[key]}`)
              .join("")
          : undefined,
        status: query?.status?.join(","),
        startDate: query?.startDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        endDate: query?.endDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        createdDate: query?.createdDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        renewalDate: query?.renewalDate
          ?.filter((date) => !!date)
          .map((date) => dayjsExt(date).toISOString())
          .join(","),
        dispatchLocations: query?.dispatchLocations?.join(","),
        search: query?.search,
      }),
    {
      staleTime: DEFAULT_STALE_TIME,
      refetchInterval: 5 * 1000,
      enabled: !!token,
    }
  );

  const { data, isLoading, ...rest } = result;

  return {
    data: data ?? { total: 0, data: [] },
    isLoading: isLoading || !token,
    ...rest,
  };
};
