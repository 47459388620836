import { Experience } from "@busie/api";
import {
  useAuthTokenWithAudience,
  usePagination,
  useUserContext,
} from "@busie/core";
import { notificationStore } from "@busie/features";
import { QuoteAndBookingStatus } from "@busie/utils";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { BookingsTable, PageHeader } from "../entity";
import {
  ListQuotesQuery,
  SortQuotesQuery,
  bookingsStore,
  useFetchBooking,
  useListBookings,
} from "../model";
import { ListControls } from "../shared/ui";
import {
  BookingDetailsDrawer,
  ChefBookingDetailsDrawer,
  ChefBookingsTable,
  CreateTrip,
  TripDetailsDrawer,
  UpdateQuote,
  UpdateTrip,
} from "../widgets";

interface Props {
  isCustomerView?: boolean;
}

const DEFAULT_REFETCH_INTERVAL = 5 * 1000; // 5 seconds

export const BookingsPage: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ isCustomerView }) => {
    const tripsAuthToken = useAuthTokenWithAudience("trips");
    const quotesAuthToken = useAuthTokenWithAudience("quotesAndBookings");
    const customersAuthToken = useAuthTokenWithAudience("customers");
    const dispatchLocationsAuthToken =
      useAuthTokenWithAudience("dispatchLocations");
    const paymentsAuthToken = useAuthTokenWithAudience("payments");

    const [currentPage, setPage] = usePagination();
    const [chefPage, setChefPage] = useState(1);

    React.useEffect(() => {
      bookingsStore.setAuthTokens({
        trips: tripsAuthToken,
        quotes: quotesAuthToken,
        customers: customersAuthToken,
        dispatchLocations: dispatchLocationsAuthToken,
        payments: paymentsAuthToken,
      });
      if (
        bookingsStore.items.length === 0 &&
        tripsAuthToken &&
        quotesAuthToken &&
        customersAuthToken &&
        dispatchLocationsAuthToken
      ) {
        bookingsStore.setItems(currentPage);
      }
    }, [
      tripsAuthToken,
      quotesAuthToken,
      customersAuthToken,
      dispatchLocationsAuthToken,
      currentPage,
      paymentsAuthToken,
    ]);

    const history = useHistory();

    const onAddQuote = () => {
      history.push("/quotes-and-bookings/add-quote");
    };

    const [sort, setSort] = useState<SortQuotesQuery>({
      CREATED_AT: "desc",
    });
    const [query, setQuery] = useState<ListQuotesQuery>({
      status: [
        QuoteAndBookingStatus.PENDING,
        QuoteAndBookingStatus.CONFIRMED,
        QuoteAndBookingStatus.PAID,
      ],
      dispatchLocations: [],
      startDate: [null, null],
      createdDate: [null, null],
    });

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [bookingId, setBookingId] = useState(searchParams.get("bookingId"));
    const [bookingsRefetchInterval, setBookingsRefetchInterval] = useState(
      DEFAULT_REFETCH_INTERVAL
    );
    const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(false);
    const [isEditTripOpen, setIsEditTripOpen] = useState(false);
    const [isEditBookingOpen, setIsEditBookingOpen] = useState(false);
    const [isCreateTripOpen, setIsCreateTripOpen] = useState(false);
    const [isTripDetailsOpen, setIsTripDetailsOpen] = useState(false);
    const [trip, setTrip] = useState<Experience>();

    const {
      data: booking,
      isError: isFetchBookingError,
      isLoading: isLoadingBooking,
    } = useFetchBooking(bookingId as string, bookingsRefetchInterval);

    const {
      data: bookingsWithTotal,
      isError: isListBookingsError,
      isLoading: isLoadingBookings,
    } = useListBookings(chefPage, sort, query);

    const quote = useMemo(
      () =>
        booking?.QUOTE
          ? { ...booking.QUOTE, _id: booking.QUOTE.ID }
          : undefined,
      [booking?.QUOTE]
    );

    const { isPremium: rolloutChef } = useUserContext();

    useEffect(() => {
      if (isListBookingsError && rolloutChef)
        notificationStore.setErrorNotification(
          "Error",
          "Could not retrieve quotes list"
        );
    }, [isListBookingsError, bookingsWithTotal, rolloutChef]);

    useEffect(() => {
      if (isFetchBookingError && rolloutChef) {
        notificationStore.setErrorNotification(
          "Not Found",
          "Could not find a quote with the specified id"
        );
      }
    }, [isFetchBookingError, booking, rolloutChef]);

    useEffect(() => {
      if (bookingsRefetchInterval !== DEFAULT_REFETCH_INTERVAL) {
        setTimeout(() => {
          setBookingsRefetchInterval(DEFAULT_REFETCH_INTERVAL);
        }, bookingsRefetchInterval * 6);
      }
    }, [bookingsRefetchInterval]);

    useEffect(() => {
      setIsBookingDetailsOpen(!!bookingId);
    }, [bookingId]);

    return (
      <>
        {!isCustomerView && !rolloutChef && <PageHeader />}

        {!rolloutChef ? (
          <>
            <BookingsTable
              bookings={bookingsStore.items}
              bookingsResponse={bookingsStore.bookingsResponse || null}
              dispatchLocations={bookingsStore.dispatchLocations}
              currentPage={currentPage}
              setPage={setPage}
            />

            <ListControls store={bookingsStore} />

            <BookingDetailsDrawer
              onListUpdate={() => bookingsStore.setItems(currentPage)}
              authTokens={{
                trips: tripsAuthToken,
                quotes: quotesAuthToken,
                customers: customersAuthToken,
                payments: paymentsAuthToken,
              }}
              isCustomerView={isCustomerView}
            />
          </>
        ) : (
          <>
            <ChefBookingsTable
              bookings={bookingsWithTotal}
              currentPage={chefPage}
              isLoading={isLoadingBookings}
              defaultQuery={{
                status: query.status,
                dispatchLocations: query.dispatchLocations,
                startDate: query.startDate,
                createdDate: query.createdDate,
                search: query.search,
                assigneeId: query.assigneeId,
              }}
              onRowClick={(id) => setBookingId(id)}
              onPageChange={(newPage) => setChefPage(newPage)}
              onQueryChange={(query) =>
                setQuery((curr) => ({ ...curr, ...query }))
              }
              onSortChange={useCallback((sort) => setSort(sort), [])}
              onAddNewQuote={onAddQuote}
              onSearchChange={(search) =>
                setQuery((curr) => ({ ...curr, search }))
              }
            />

            <ChefBookingDetailsDrawer
              booking={booking}
              isOpen={isBookingDetailsOpen}
              isLoading={isLoadingBooking}
              onEditBooking={() => setIsEditBookingOpen(true)}
              onAddTrip={() => setIsCreateTripOpen(true)}
              onClose={() => {
                setIsBookingDetailsOpen(false);
                setBookingId(null);
              }}
              onTripClicked={(trip) => {
                setTrip(trip);
                setIsTripDetailsOpen(true);
              }}
              onBookingUpdated={() => setBookingsRefetchInterval(2000)}
            />

            {booking ? (
              <CreateTrip
                isOpen={isCreateTripOpen}
                quoteId={booking.QUOTE.ID}
                organizationId={booking.QUOTE.ORGANIZATION_ID}
                onClose={() => setIsCreateTripOpen(false)}
                customerGroupId={booking.QUOTE.CUSTOMER_GROUP_ID}
                mainContactId={
                  booking.QUOTE.MAIN_CONTACT_ID ||
                  booking.QUOTE.EXPERIENCES[0].MAIN_CONTACT?.ID
                }
                onTripCreated={() => {
                  setIsCreateTripOpen(false);
                  setBookingsRefetchInterval(2000);
                }}
              />
            ) : null}

            {trip && (
              <TripDetailsDrawer
                isOpen={isTripDetailsOpen}
                trip={trip}
                onClose={() => setIsTripDetailsOpen(false)}
                onEditTrip={() => setIsEditTripOpen(true)}
              />
            )}

            {trip && (
              <UpdateTrip
                isOpen={isEditTripOpen}
                onClose={() => setIsEditTripOpen(false)}
                trip={trip}
                onSuccess={() => {
                  setIsEditTripOpen(false);
                  setBookingsRefetchInterval(2000);
                }}
              />
            )}

            {quote && (
              <UpdateQuote
                isOpen={isEditBookingOpen}
                quote={quote}
                onClose={() => setIsEditBookingOpen(false)}
                onSuccess={() => {
                  setBookingsRefetchInterval(2000);
                  setIsEditBookingOpen(false);
                }}
                onAddTrip={() => setIsCreateTripOpen(true)}
              />
            )}
          </>
        )}
      </>
    );
  }
);
