import { AnalyticsPageNoContent } from "~/AnalyticsPage/entity";
import { AnalyticsTabs } from "~/AnalyticsPage/widgets";

interface Props {
  isPremium: boolean;
}

export const Content: React.FC<Props> = ({ isPremium }) => {
  return isPremium ? <AnalyticsTabs /> : <AnalyticsPageNoContent />;
};
