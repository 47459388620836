import { QueryKey, useQuery } from "react-query";

import {
  ListDriversParams,
  fetchDriver,
  fetchDrivers,
  fetchDriversSettings,
} from "@busie/api";
import {
  queryClient,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";

export const DRIVERS_QUERY_KEY: QueryKey = "DRIVERS";

export const invalidateDriversQueries = () => {
  queryClient.invalidateQueries(DRIVERS_QUERY_KEY);
};

export const useDriversSettings = (organizationId?: string) => {
  const driversAuthToken = useAuthTokenWithAudience("drivers");

  const result = useQuery(
    [DRIVERS_QUERY_KEY, "settings", organizationId],
    () => fetchDriversSettings(driversAuthToken, organizationId as string),
    {
      enabled: !!driversAuthToken && !!organizationId,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useFetchDrivers = (params: ListDriversParams) => {
  const driversAuthToken = useAuthTokenWithAudience("drivers");

  const result = useQuery(
    [DRIVERS_QUERY_KEY, "drivers", params],
    () => fetchDrivers(driversAuthToken, params),
    {
      enabled: !!driversAuthToken,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useFetchDriver = (driverId?: string) => {
  const driversAuthToken = useAuthTokenWithAudience("drivers");

  const result = useQuery(
    [DRIVERS_QUERY_KEY, "driver", driverId],
    () => fetchDriver(driversAuthToken, driverId as string),
    {
      enabled: !!driversAuthToken && !!driverId,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};
