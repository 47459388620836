import React, { useCallback } from "react";
import { styled } from "@mui/system";
import {
  Button,
  FlexContainer,
  Select,
  DateRangePicker,
  ChevronPrev,
  Download,
} from "@busie/ui-kit";
import { useHistory } from "react-router";
import { DateRange, VehicleTypes, VehicleTypesValues } from "@busie/utils";
import { mapVehicleTypeToName } from "@busie/core";

const Header = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "48px",
  };
});

const Container = styled("div")(() => ({
  width: "100%",
  maxWidth: 1360,
}));

const ButtonContainer = styled(FlexContainer)(() => ({
  padding: "20px",
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const VehicleListContainer: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => {
  const [selectedVehicleType, setSelectedVehicleType] =
    React.useState<VehicleTypes>(VehicleTypes.NONE);
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([
    null,
    null,
  ]);
  const { children } = props;
  const history = useHistory();
  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const selectOptions = [
    {
      name: "All vehicles",
      value: -1,
    },
    ...VehicleTypesValues.map((vType) => ({
      name: mapVehicleTypeToName(vType),
      value: vType,
    })),
  ];

  return (
    <Container>
      <Header>
        <Button
          typestyle="secondary"
          startIcon={<ChevronPrev />}
          onClick={onBackClick}
        >
          Back
        </Button>

        <h2>Vehicle statistics</h2>
        <span />
      </Header>

      <ButtonContainer justify="space-between" bordered>
        <FlexContainer justify="flex-start" align="center" columnGap={3}>
          <Select
            onChange={(e) => setSelectedVehicleType(e.target.value)}
            value={selectedVehicleType}
            items={selectOptions}
          />
          <DateRangePicker
            value={selectedDateRange}
            disablePast={false}
            onChange={(dr) => setSelectedDateRange(dr)}
          />
        </FlexContainer>

        <FlexContainer justify="flex-end" align="center">
          <Button typestyle="secondary" startIcon={<Download />}>
            Download report
          </Button>
        </FlexContainer>
      </ButtonContainer>

      {children}
    </Container>
  );
};

export default VehicleListContainer;
