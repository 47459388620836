import { Tab as BaseTab } from "@mui/base/Tab";
import { styled } from "@mui/system";

export const Tab = styled(BaseTab)(({ theme }) => {
  const {
    palette: { black },
    breakpoints,
  } = theme;

  return {
    borderRadius: "4px",
    color: black.plus10,
    background: black.plus100,
    fontFamily: "inherit",
    padding: "9px 16px",
    textDecoration: "none",
    border: "0",
    cursor: "pointer",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "100%",
    letterSpacing: "0.04em",
    transition: "background .2s ease, color .2s ease",
    "&:hover": {
      background: black.plus85,
    },
    "&.Mui-selected": {
      cursor: "default",
      color: black.plus100,
      background: black.plus10,
    },
    [breakpoints.down("tablet")]: {
      fontSize: "12px",
    },
  };
});
