export enum VehicleTypes {
  NONE = -1,
  MOTOR_COACH,
  MINI_BUS,
  BLACK_CAR,
  ENTERTAINER_COACH,
  EXECUTIVE_COACH,
  SLEEPER_COACH,
  DOUBLE_DECKER,
  SCHOOL_BUS,
  VAN,
  TROLLEY,
}

export const VehicleTypesValues = Object.values(VehicleTypes)
  .filter((vehicleType) => typeof vehicleType !== "string")
  .map((vehicleType) => vehicleType as VehicleTypes);
