import { FC, useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  Popup,
  FlexContainer,
  NumberInput,
  palette,
  DeleteIcon,
  PlusIcon,
  InputText,
} from "@busie/ui-kit";
import { percentageValidation } from "../helpers";
import { IntegrationIcon } from "../CardsGrid/IntegrationCard/styled";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@busie/utils";

import { Integration } from "@busie/utils";
import { QuoteIntegrationsStore } from "~/settings/store";
import { paymentStore } from "~/settings/store";
import { getRevenueShareConfig } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  integration?: Integration;
}

export const UpdateIntegrationModal: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const [revenue, setRevenue] = useState<number>(0);
  const [initialRevenue, setInitialRevenue] = useState<number>(0);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth0<User>();
  const paymentAuthToken = useAuthTokenWithAudience("payments");

  useEffect(() => {
    setEnabled(props.integration?.status === "ACTIVE");
  }, [props.integration]);

  useEffect(() => {
    if (paymentAuthToken) {
      paymentStore.setAuthToken(paymentAuthToken);
    }
  }, [paymentAuthToken]);

  useEffect(() => {
    if (props.integration && paymentAuthToken) {
      setLoading(true);
      getRevenueShareConfig(
        paymentAuthToken,
        props.integration.revenueShareConfigId
      )
        .then((revenueShare) => {
          if (revenueShare && Array.isArray(revenueShare.destinations)) {
            const destination = revenueShare.destinations.find(
              (d) => !d.publicIntegrationKey
            );
            destination && setRevenue(destination.apportionmentValue);
            setInitialRevenue(revenueShare.platformApportionmentValue);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [paymentAuthToken, props.integration]);

  const onDisable = async () => {
    setLoading(true);
    await QuoteIntegrationsStore.rejectIntegration(
      props.integration?.id as string
    );
    setEnabled(false);
    setLoading(false);
  };

  const onEnable = async () => {
    setLoading(true);
    if (props.integration?.integrationType === "SOURCE") {
      await QuoteIntegrationsStore.approveIntegration(
        props.integration?.id as string,
        "PENDING"
      );
    } else {
      await QuoteIntegrationsStore.approveIntegration(
        props.integration?.id as string
      );
    }

    setEnabled(true);
    setLoading(false);
  };

  const submitForm = async () => {
    if (props.integration) {
      const orgId = user?.org_id || "";
      const otherOrgId =
        props.integration.integrationType === "SOURCE"
          ? props.integration.destinationId
          : props.integration.sourceId;
      await paymentStore.updateRevenueShare(
        orgId,
        otherOrgId,
        props.integration.revenueShareConfigId,
        revenue
      );
    }
    props.onClose();
  };

  return props.integration ? (
    <Popup isPopupOpened={props.isOpen} title="Organization information">
      <FlexContainer
        w={460}
        px={5}
        py={5}
        rowGap={1}
        direction="column"
        fullWidth
      >
        {loading && <CircularProgress />}
        {!loading && (
          <>
            <IntegrationIcon src={props.integration?.logo} noPadding />
            <FlexContainer align="center" justify="center" fullWidth>
              <InputText color={palette.black.plus50}>
                {props.integration.integrationType === "SOURCE"
                  ? "Source"
                  : "Destination"}
              </InputText>
            </FlexContainer>
            <InputText color={palette.red.main}>
              Platform Fee: {initialRevenue || 0}%
            </InputText>
            <NumberInput
              label="Revenue share %"
              isAllowed={(value) => percentageValidation(value, initialRevenue)}
              suffix="%"
              value={revenue}
              onChange={(e) => setRevenue(Number(e.target.value))}
              fullWidth
              disabled={props.integration?.integrationType !== "SOURCE"}
            />
            <FlexContainer
              justify="space-between"
              align="center"
              pt={2}
              fullWidth
            >
              {enabled ? (
                <Button
                  startIcon={<DeleteIcon />}
                  typestyle="tertiary"
                  size="small"
                  onClick={onDisable}
                  disabled={loading}
                >
                  Disable
                </Button>
              ) : (
                <Button
                  startIcon={<PlusIcon />}
                  typestyle="tertiary"
                  size="small"
                  onClick={onEnable}
                  disabled={loading}
                >
                  Enable
                </Button>
              )}
              <FlexContainer justify="flex-end" columnGap={1}>
                <Button typestyle="secondary" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  onClick={submitForm}
                  async
                  disabled={props.integration?.integrationType !== "SOURCE"}
                >
                  Update
                </Button>
              </FlexContainer>
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </Popup>
  ) : null;
};
