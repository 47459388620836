import { useDebouncedCallback } from "@busie/core";
import {
  FlexContainer,
  Loading,
  SearchInput,
  TablePagination,
} from "@busie/ui-kit";
import { Customer, Group } from "@busie/utils";
import React, { useState } from "react";
import { AddCustomerButton } from "./AddCustomerButton";
import { EmptyCustomers } from "./EmptyCustomers";
import { ORGANIZATIONS_PER_PAGE, useCustomersPageOrganizations } from "./model";
import { CustomersContainerStyled } from "./styled";
import { GroupDrawer, GroupsTable, ProfileDrawer } from "./widgets";

export const CustomersPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [isGroupDrawerOpen, setIsGroupDrawerOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    isFetched: areOrganizationsFetched,
    isPreviousData,
    data: organizationsData,
  } = useCustomersPageOrganizations({
    query: searchQuery,
    currentPage,
  });
  const organizations = organizationsData?.results || [];
  const areCustomersEmpty =
    areOrganizationsFetched && organizations.length === 0 && !searchQuery;

  const debouncedSetSearchQuery = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
    setCurrentPage(1);
  }, 700);

  if (!areOrganizationsFetched && !isPreviousData) {
    return <Loading />;
  }

  const onClickCustomer = (customer: Customer) => {
    setIsProfileDrawerOpen(true);
    setSelectedCustomerId(customer.id);
  };

  const onClickGroup = (group: Group) => {
    setIsGroupDrawerOpen(true);
    setSelectedGroupId(group.id);
  };

  return (
    <CustomersContainerStyled>
      {areCustomersEmpty ? (
        <EmptyCustomers />
      ) : (
        <>
          <FlexContainer
            align="center"
            justify="space-between"
            w="100%"
            px={3}
            py={2}
          >
            <SearchInput
              placeholder="Search by Group Name"
              search={debouncedSetSearchQuery}
            />
            <AddCustomerButton />
          </FlexContainer>

          <GroupsTable
            // TODO: Customer Group table controls should be a feature used within the GroupsTable widget
            onCustomerClick={onClickCustomer}
            onGroupClick={onClickGroup}
            isLoading={!areOrganizationsFetched}
            areCustomersNotFound={organizations.length === 0 && !!searchQuery}
            organizations={organizations}
          />

          {!!organizationsData && organizationsData.total > 0 && (
            <TablePagination
              itemsTotal={organizationsData.total}
              pageNumber={currentPage}
              perPage={ORGANIZATIONS_PER_PAGE}
              currentOffset={ORGANIZATIONS_PER_PAGE * (currentPage - 1)}
              nextOffset={
                organizationsData.nextOffset || organizationsData.total
              }
              onNextClick={() => setCurrentPage(currentPage + 1)}
              onPrevClick={() => setCurrentPage(currentPage - 1)}
              onToEndClick={() =>
                setCurrentPage(
                  Math.ceil(organizationsData.total / ORGANIZATIONS_PER_PAGE)
                )
              }
              onToBeginningClick={() => setCurrentPage(1)}
              outlined={false}
            />
          )}

          {!!selectedCustomerId && (
            <ProfileDrawer
              isOpen={isProfileDrawerOpen}
              customerId={selectedCustomerId}
              onClose={() => setIsProfileDrawerOpen(false)}
            />
          )}

          {!!selectedGroupId && (
            <GroupDrawer
              isOpen={isGroupDrawerOpen}
              groupId={selectedGroupId}
              onClose={() => setIsGroupDrawerOpen(false)}
            />
          )}
        </>
      )}
    </CustomersContainerStyled>
  );
};
