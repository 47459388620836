import React from "react";
import { observer } from "mobx-react-lite";

import { usePagination, useAuthTokenWithAudience } from "@busie/core";

import { ArchivedBookingsTable, PageHeader } from "../entity";
import { archivedBookingsStore } from "../model";
import { BookingDetailsDrawer } from "../widgets";

interface Props {
  isCustomerView?: boolean;
}

export const ArchivedBookingsPage: React.FC<React.PropsWithChildren<Props>> =
  observer(({ isCustomerView }) => {
    const tripsAuthToken = useAuthTokenWithAudience("trips");
    const quotesAuthToken = useAuthTokenWithAudience("quotesAndBookings");
    const customersAuthToken = useAuthTokenWithAudience("customers");
    const dispatchLocationsAuthToken =
      useAuthTokenWithAudience("dispatchLocations");
    const paymentsAuthToken = useAuthTokenWithAudience("payments");

    const [currentPage, setPage] = usePagination();

    React.useEffect(() => {
      archivedBookingsStore.setAuthTokens({
        trips: tripsAuthToken,
        quotes: quotesAuthToken,
        customers: customersAuthToken,
        dispatchLocations: dispatchLocationsAuthToken,
      });
      if (
        archivedBookingsStore.items.length === 0 &&
        tripsAuthToken &&
        quotesAuthToken &&
        customersAuthToken &&
        dispatchLocationsAuthToken
      ) {
        archivedBookingsStore.setItems(currentPage);
      }
    }, [
      tripsAuthToken,
      quotesAuthToken,
      customersAuthToken,
      dispatchLocationsAuthToken,
      currentPage,
    ]);

    return (
      <>
        {!isCustomerView && <PageHeader />}
        <ArchivedBookingsTable
          bookings={archivedBookingsStore.items}
          bookingsResponse={archivedBookingsStore.bookingsResponse || null}
          dispatchLocations={archivedBookingsStore.dispatchLocations}
          currentPage={currentPage}
          setPage={setPage}
        />

        <BookingDetailsDrawer
          onListUpdate={() => archivedBookingsStore.setItems(currentPage)}
          authTokens={{
            trips: tripsAuthToken,
            quotes: quotesAuthToken,
            customers: customersAuthToken,
            payments: paymentsAuthToken,
          }}
          isCustomerView
        />
      </>
    );
  });
