import { useMutation } from "react-query";

import { deleteCustomer } from "@busie/api";

import { customersModel } from "~/CustomersPage/entity";
import { useAuthTokenWithAudience } from "@busie/core";

interface DeleteCustomerParams {
  id: string;
}

export const useDeleteCustomer = () => {
  const customersAuthToken = useAuthTokenWithAudience("customers");

  const mutation = useMutation(async ({ id }: DeleteCustomerParams) => {
    await deleteCustomer(customersAuthToken, id);
    customersModel.invalidateCustomersQueries();
  });

  return mutation;
};
