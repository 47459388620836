import React from "react";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/system";

import store from "~/rates/RateMenuPage/store";

import CreateRecipeModal from "~/rates/RateMenuPage/components/CreateRecipeModal";

import {
  CookbooksTableView,
  CookbooksTableViewRef,
} from "~/rates/RateMenuPage/components/CookbooksTableView/CookbooksTableView";
import RecipeDetailsDrawer from "~/rates/RateMenuPage/components/CookbookDetailsDrawer/RecipeDetailsDrawer";
import { useAuthTokenWithAudience } from "@busie/core";

const RateMenuContainer = styled("div")(() => {
  return {
    paddingBottom: "0",
  };
});

const RateMenuPage: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const authToken = useAuthTokenWithAudience("rates");
    let tableViewRef: CookbooksTableViewRef | null = null;

    const onRecipeCreate = async () => {
      await tableViewRef?.refetch();
    };

    return (
      <RateMenuContainer>
        <CreateRecipeModal
          authToken={authToken}
          handleClose={store.createRecipeModal.toggleCreateRecipeModal}
          onSuccess={onRecipeCreate}
          isPopupOpened={store.createRecipeModal.isModalWindowOpened}
        />
        <CookbooksTableView
          onAddRateRecipe={store.createRecipeModal.toggleCreateRecipeModal}
          ref={(ref) => {
            tableViewRef = ref;
          }}
        />
        <RecipeDetailsDrawer authToken={authToken} />
      </RateMenuContainer>
    );
  }
);

export default RateMenuPage;
