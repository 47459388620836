export * from "./client";

export {
  QUERY_KEY as TRIPS_QUERY_KEY,
  invalidate as invalidateTrips,
} from "./trips";

export { invalidate as invalidatePlans, usePlan } from "./plans";

export {
  invalidate as invalidateUsers,
  useUsers,
  type UsersMap,
} from "./users";

export * from "./groups";

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

export const STALE_TIMES = {
  DEFAULT: DEFAULT_STALE_TIME,
};
