import React from "react";
import { observer } from "mobx-react";
import { useAuth0 } from "@auth0/auth0-react";

import { useAmplitude, useAuthTokenWithAudience } from "@busie/core";
import { FilterSelector, FlexContainer, Loading } from "@busie/ui-kit";
import { isDispatchLocationPatchAllowed, User } from "@busie/utils";
import { notificationStore } from "@busie/features";
import { getTripType } from "~/QuotesAndBookingsPage/shared/lib";

import { EditTripForm } from "./EditTripForm";
import { EditQuoteForm } from "./EditQuoteForm";
import { store } from "./model";

export type EditFormType = "quote" | "booking";
export type TabItem = "Trip Details" | "Quote Summary";

interface Props {
  type: EditFormType;
  id: string;
  onClose: () => void;
}

export const EditForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ type, id, onClose }) => {
    const [selectedTab, setSelectedTab] =
      React.useState<TabItem>("Trip Details");
    const quotesAuthToken = useAuthTokenWithAudience("quotesAndBookings");
    const tripsAuthToken = useAuthTokenWithAudience("trips");
    const customerAuthToken = useAuthTokenWithAudience("customers");
    const dispatchLocationToken = useAuthTokenWithAudience("dispatchLocations");
    const pathFinderAuthToken = useAuthTokenWithAudience("pathfinder");
    const { user } = useAuth0<User>();
    const { track } = useAmplitude();

    React.useEffect(() => {
      if (quotesAuthToken && tripsAuthToken && dispatchLocationToken && user) {
        if (type === "booking") {
          store.setBookingId(id);
          store.setQuoteId(id);
          store.setItems(
            quotesAuthToken,
            tripsAuthToken,
            true,
            isDispatchLocationPatchAllowed(user),
            dispatchLocationToken
          );
        } else {
          store.setQuoteId(id);
          store.setItems(
            quotesAuthToken,
            tripsAuthToken,
            false,
            isDispatchLocationPatchAllowed(user),
            dispatchLocationToken
          );
        }
      }
      return () => store.resetForm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quotesAuthToken, tripsAuthToken, dispatchLocationToken, user, id]);

    const onSubmit = async () => {
      try {
        await store.submit(tripsAuthToken, quotesAuthToken);
        if (store.mainContactId !== store.trip?._mainContactId) {
          await store.submitTrip(tripsAuthToken, pathFinderAuthToken);
        }
        notificationStore.setNotification({
          type: "success",
          header: "Success",
          message: "Quote updated successfully",
        });
        track("quote updated", {
          quoteId: store.quote?._id,
          price: store.quote?._totalPrice,
          tripId: store.quote?._experienceId,
          numberOfLegs: store.trip?._legs.length,
          numberOfPassengers: store.trip?._passengers,
          isRoundTrip: store.trip
            ? getTripType(
                store.trip?._legs[0]._destinationLocation,
                store.trip._legs[store.trip._legs.length - 1]._startLocation
              ) === "round_trip"
            : false,
        });
      } catch (err) {
        notificationStore.setErrorNotification(
          "Could not update quote details",
          (err as Error).message || "An unknown error occurred"
        );
      }
    };

    const onTripSubmit = async () => {
      try {
        await store.submitTrip(tripsAuthToken, pathFinderAuthToken);
        notificationStore.setNotification({
          type: "success",
          header: "Success",
          message: "Trip updated successfully",
        });
        track("trip updated", {
          tripId: store.quote?._experienceId,
          numberOfLegs: store.trip?._legs.length,
          numberOfPassengers: store.trip?._passengers,
          mainContactId: store.trip?._mainContactId,
          isRoundTrip: store.trip
            ? getTripType(
                store.trip?._legs[0]._destinationLocation,
                store.trip._legs[store.trip._legs.length - 1]._startLocation
              ) === "round_trip"
            : false,
        });
      } catch (err) {
        notificationStore.setErrorNotification(
          "Could not update trip details",
          (err as Error).message || "An unknown error occured"
        );
      }
    };

    React.useEffect(() => {
      if (store.submittingStatus === "fetched" && user) {
        store.setQuoteId(id);
        store.setItems(
          quotesAuthToken,
          tripsAuthToken,
          false,
          isDispatchLocationPatchAllowed(user),
          dispatchLocationToken
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.submittingStatus]);

    React.useEffect(() => {
      if (customerAuthToken && store.trip?._tripPlannerId && !store.customer) {
        store.fetchCustomer(customerAuthToken, store.trip?._tripPlannerId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerAuthToken, store.trip?._tripPlannerId]);

    if (
      user &&
      store.trip &&
      store.quote &&
      store.quoteId &&
      (type === "booking" ? store.booking && store.bookingId : true)
    ) {
      return (
        <FlexContainer direction="column" rowGap={1} px={2}>
          <FlexContainer columnGap={0.75}>
            <FilterSelector
              options={[
                {
                  name: "Trip Details",
                  link: "#",
                },
                {
                  name: "Quote Summary",
                  link: "#",
                },
              ]}
              value={selectedTab}
              withState
              onChange={(value) => setSelectedTab(value as TabItem)}
            />
          </FlexContainer>

          {selectedTab === "Trip Details" && (
            <EditTripForm
              type={type}
              onSubmit={onTripSubmit}
              onClose={onClose}
            />
          )}
          {selectedTab === "Quote Summary" && (
            <EditQuoteForm type={type} onSubmit={onSubmit} onClose={onClose} />
          )}
        </FlexContainer>
      );
    }

    return <Loading />;
  }
);
