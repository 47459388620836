import { Tab, TabPanel } from "../../ui";
import { ReactElement, useMemo } from "react";
import { AnalyticsIframe } from "~/AnalyticsPage/features/";
import { DASHBOARD_OPTIONS } from "../constants";

type DashboardElements = {
  tabElements: ReactElement[];
  panelElements: ReactElement[];
};

export const useDashboardElements = (): DashboardElements => {
  const { tabElements, panelElements } = useMemo(() => {
    return DASHBOARD_OPTIONS.reduce<DashboardElements>(
      (acc, { id, label }) => {
        acc.tabElements.push(
          <Tab key={`${id}-tab`} value={id}>
            {label}
          </Tab>
        );
        acc.panelElements.push(
          <TabPanel key={`${id}-panel`} value={id}>
            <AnalyticsIframe dashboard={id} />
          </TabPanel>
        );
        return acc;
      },
      { tabElements: [], panelElements: [] }
    );
  }, []);

  return { tabElements, panelElements };
};
