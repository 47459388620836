import { styled, Theme } from "@mui/system";

export const CustomersContainerStyled = styled("div")(
  (props: { theme?: Theme }) => {
    const palette = props.theme?.palette;
    return {
      background: palette?.black?.plus100,
      border: `1px ${palette?.black?.plus70} solid`,
      borderRadius: "4px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 16,
      marginBottom: 16,
      maxWidth: "1360px",
      alignSelf: "center",
      width: "100%",
    };
  }
);
