import { StylesConfig } from "react-select";
import { palette } from "../../";
import { SelectAsyncOption } from "./";

export const selectStyles: StylesConfig<SelectAsyncOption, false> = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    padding: "0",
    background: palette?.black?.plus100,
    borderColor: palette?.black?.plus70,
    boxShadow: state.isFocused ? `0 0 0 1px ${palette?.black?.plus70}` : "none",
    minHeight: "40px",
    outline: state.isFocused ? palette?.black?.plus70 : palette?.black?.plus70,
    "&:hover": {
      borderColor: palette?.black?.plus70,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: "100%",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.04em",
    minHeight: "auto",
    color: palette.black.plus10,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: palette?.black?.plus10,
  }),
  menu: (provided) => ({
    ...provided,
    borderColor: palette?.black?.plus10,
    padding: 0,
    paddingTop: "0px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? palette?.black?.plus70
      : provided.backgroundColor,
  }),
};
