import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

import {
  convertAmenityToETPAmenity,
  EmbeddedTripPlannerSettings,
  UpdateEmbeddedTripPlannerSettingsParams,
} from "@busie/api";
import {
  Button,
  FormContainer,
  palette,
  FlexContainer,
  H2,
  TextInput,
  InputText,
  Callout,
  ToggleSwitch,
  BigIconSelect,
} from "@busie/ui-kit";
import { schema } from "./model/schema";
import { AmenityIcon, mapAmenityToName } from "@busie/core";
import { AmenityValues, toggleSetItem } from "@busie/utils";
import { defaultAmenitiesFilter } from "./utils";

interface Props {
  settings: EmbeddedTripPlannerSettings;
  onSubmit: (data: UpdateEmbeddedTripPlannerSettingsParams) => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  settings,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm<UpdateEmbeddedTripPlannerSettingsParams>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      domain: settings._domain,
      hideInstantQuote: settings._hideInstantQuote,
      requestToBook: settings._requestToBook,
      displayedAmenities: settings._displayedAmenities,
    },
  });

  const [isHideInstantQuote, setIsHideInstantQuote] = useState(
    settings._hideInstantQuote
  );

  const setHideInstantQuote = (value: boolean) => {
    setValue("hideInstantQuote", value);
    setIsHideInstantQuote(value);
    if (value) {
      setRequestToBook(false);
    }
  };

  const setRequestToBook = (value: boolean) => {
    setValue("requestToBook", value);
  };

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="" fullWidth bordered={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer direction="column" rowGap={4}>
          <FlexContainer direction="column" rowGap={1}>
            <H2>Enable Instant Quoting</H2>

            <InputText color={palette.black.plus30}>
              Instant Quotes allow your customer to immediately see the
              estimated price for a trip. You'll still have the opportunity to
              gather more details from the customer, and make changes as
              necessary.
            </InputText>

            <Callout>
              <span role="img" aria-label="info">
                🤓 ☝️
              </span>{" "}
              <em>
                Instant quotes can be very effective at increasing conversion
                and weeding out unqualified leads or quote shoppers!
              </em>
            </Callout>

            <Controller
              name="hideInstantQuote"
              control={control}
              render={({ field: { value } }) => (
                <ToggleSwitch
                  onChange={() => setHideInstantQuote(!value)}
                  checked={!value}
                />
              )}
            />
          </FlexContainer>
        </FlexContainer>
        <br />
        <FlexContainer direction="column" rowGap={4}>
          <FlexContainer direction="column" rowGap={1}>
            <H2>Enable Request to Book</H2>

            <InputText color={palette.black.plus30}>
              Request to book allows your customer to let your team know that
              they are ready to book
            </InputText>

            <Callout>
              <em>
                Request to book can only be enabled if instant quoting is
                enabled.
              </em>
            </Callout>

            <Controller
              name="requestToBook"
              control={control}
              render={({ field: { value } }) => (
                <ToggleSwitch
                  onChange={() => setRequestToBook(!value)}
                  checked={value}
                  disabled={isHideInstantQuote}
                />
              )}
            />
          </FlexContainer>
        </FlexContainer>

        <br />

        <FlexContainer direction="column" rowGap={4}>
          <FlexContainer direction="column" rowGap={1}>
            <H2>Displayed Amenities</H2>
            <InputText color={palette.black.plus30}>
              Choose which amenities you would like to display in the Embedded
              Trip Planner.
            </InputText>

            <Callout>
              <span role="img" aria-label="info">
                ℹ️
              </span>{" "}
              <em>
                Busie's automated vehicle selection will prioritize vehicles
                with a Trip Planner's requested amenities, but will still select
                vehicles without the requested amenities if necessary.
              </em>
            </Callout>

            <Controller
              name="displayedAmenities"
              control={control}
              render={({ field }) => (
                <FlexContainer
                  columnGap={2}
                  rowGap={2.5}
                  fullWidth
                  wrap="wrap"
                  justify="flex-start"
                >
                  {AmenityValues.filter(defaultAmenitiesFilter).map(
                    (amenity, index) => {
                      const displayedAmenitySet = new Set(
                        getValues("displayedAmenities")
                      );
                      return (
                        <BigIconSelect
                          title={mapAmenityToName(amenity)}
                          key={index}
                          isActive={displayedAmenitySet.has(
                            convertAmenityToETPAmenity(amenity)
                          )}
                          onClick={() =>
                            field.onChange(
                              Array.from(
                                toggleSetItem(
                                  displayedAmenitySet,
                                  convertAmenityToETPAmenity(amenity)
                                )
                              )
                            )
                          }
                        >
                          <AmenityIcon type={amenity} />
                        </BigIconSelect>
                      );
                    }
                  )}
                </FlexContainer>
              )}
            />
          </FlexContainer>
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
