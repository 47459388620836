import React from "react";

import { Customer, Group, Quote, Trip } from "@busie/utils";
import { DrawerDetailsBlock, Loading, PropertyString } from "@busie/ui-kit";
import { fetchCustomer, fetchGroup } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";

interface Props {
  trip: Trip;
  quote: Quote;
}

export const TripPlanner: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  quote,
}: Props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tripPlanner, setTripPlanner] = React.useState<Customer>(
    {} as Customer
  );
  const [tripPlannerGroup, setTripPlannerGroup] = React.useState<Group>(
    {} as Group
  );
  const authToken = useAuthTokenWithAudience("customers");
  React.useEffect(() => {
    if (!authToken) return;

    const getTripPlannerData = async () => {
      const tripPlanner = await fetchCustomer(authToken, trip._tripPlannerId);
      const tripPlannerGroup = await fetchGroup(
        authToken,
        quote._customerGroupId
      );
      setTripPlanner(tripPlanner);
      setTripPlannerGroup(tripPlannerGroup);
      setLoading(false);
    };
    getTripPlannerData();
    return () => {
      setTripPlanner({} as Customer);
      setTripPlannerGroup({} as Group);
      setLoading(false);
    };
  }, [authToken, trip, quote]);

  if (loading) {
    return <Loading />;
  }

  return (
    <DrawerDetailsBlock collapsible name="Trip Planner">
      <PropertyString name="Name">{tripPlanner.name}</PropertyString>
      <PropertyString name="Email">
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${tripPlanner.email}`}
          className="force-print"
        >
          {tripPlanner.email}
        </a>
      </PropertyString>
      <PropertyString name="Phone Number">
        +{tripPlanner.countryCode} ({tripPlanner.areaCode}){" "}
        {tripPlanner.phoneNumber?.slice(0, 3)}-
        {tripPlanner.phoneNumber?.slice(3)}
      </PropertyString>
      <PropertyString name="Organization">
        {tripPlannerGroup.name}
      </PropertyString>
    </DrawerDetailsBlock>
  );
};
