import { useQuery, UseQueryResult } from "react-query";

import {
  fetchCustomer,
  fetchGroup,
  PaginatedResponse,
  searchGroups,
} from "@busie/api";
import {
  queryClient,
  CUSTOMERS_QUERY_KEY,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";
import { Group } from "@busie/utils";

export const invalidateCustomersQueries = () => {
  queryClient.invalidateQueries(CUSTOMERS_QUERY_KEY);
};

interface CustomersOrganizationsParams {
  query?: string;
}

export const useCustomersOrganizations = (
  { query }: CustomersOrganizationsParams = {},
  enabled?: { enabled: boolean }
) => {
  console.log("🚀 ~ query:", query);
  const customersAuthToken = useAuthTokenWithAudience("customers");

  const result = useQuery(
    [CUSTOMERS_QUERY_KEY, "organizations", query],
    () =>
      searchGroups(customersAuthToken, {
        search: query,
        skip: 0,
        take: 100,
      }),
    {
      enabled: !!customersAuthToken && !!enabled,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useCustomersOrganization = (id: string) => {
  const { data: organizations } = useCustomersOrganizations(
    { query: undefined },
    { enabled: true }
  ) as UseQueryResult<PaginatedResponse<Group>>;

  if (!id) return;
  return organizations?.resultSet?.find(
    (organization) => organization.id === id
  );
};

export const useCustomer = (id: string) => {
  const customersAuthToken = useAuthTokenWithAudience("customers");

  const result = useQuery(
    [CUSTOMERS_QUERY_KEY, "customer", id],
    () => fetchCustomer(customersAuthToken, id),
    {
      enabled: !!customersAuthToken && !!id,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useFetchGroup = (id: string) => {
  const authToken = useAuthTokenWithAudience("customers");

  return useQuery(
    [CUSTOMERS_QUERY_KEY, "group", id],
    async () => fetchGroup(authToken, id),
    {
      enabled: !!authToken && !!id,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );
};
