import { deleteDriver } from "@busie/api";
import { useAuthTokenWithAudience } from "@busie/core";
import { useMutation } from "react-query";
import { invalidateDriversQueries } from "~/drivers/model";

export const useDeleteDriver = () => {
  const authToken = useAuthTokenWithAudience("drivers");

  const mutation = useMutation(async (id: string) => {
    await deleteDriver(authToken, id);

    invalidateDriversQueries();
  });

  return mutation;
};
