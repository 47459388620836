import React from "react";
import { observer } from "mobx-react-lite";

import { deleteIngredient } from "@busie/api";
import store from "~/rates/RateMenuPage/store";

import { WarningPopup } from "@busie/ui-kit";
import { useAuthTokenWithAudience } from "@busie/core";
//import { useQueryClient } from "react-query";

interface Props {
  id: string;
  isPopupOpened: boolean;
  onClose: () => void;
}

const DeleteIngredientPopup: React.FC<React.PropsWithChildren<Props>> =
  observer(({ isPopupOpened, onClose, id }: Props) => {
    const authToken = useAuthTokenWithAudience("rates");
    //const queryClient = useQueryClient();

    const handlerOnDelete = async () => {
      try {
        await deleteIngredient(authToken, id);
        store.rates.deleteActiveRecipeIngredient(id);
        //queryClient.invalidateQueries("rates");
      } finally {
        onClose();
      }
    };
    return (
      <WarningPopup
        title="Delete ingredient?"
        isPopupOpened={isPopupOpened}
        onClose={onClose}
        onConfirm={handlerOnDelete}
        confirmText="Delete"
      >
        Are you sure you want to delete ingredient?
      </WarningPopup>
    );
  });

export default DeleteIngredientPopup;
