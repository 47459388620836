import React from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { dispatchLocationStore } from "~/settings/store/dispatch";
import { Controller, useForm } from "react-hook-form";
import xor from "lodash.xor";
import {
  AmenityIcon,
  mapAmenityToName,
  useAuthTokenWithAudience,
} from "@busie/core";
import {
  AmountFieldStyled,
  BigIconSelect,
  Button,
  H3,
  H4,
  theme,
  Select,
  palette,
  FlexContainer,
  TextInput,
  NumberInput,
  MinusIcon,
  PlusIcon,
} from "@busie/ui-kit";
import { VehicleFormData } from "@busie/api";
import store from "~/vehicles/store/vehiclesForm.store";
import { Amenity, AmenityValues, DispatchLocation } from "@busie/utils";

import Checkbox from "./styled/CheckboxStyled";

interface VehiclesFormProps {
  onClose: () => void;
}

const VehiclesEditForm: React.FC<React.PropsWithChildren<VehiclesFormProps>> =
  observer(({ onClose }) => {
    const vehiclesAuthToken = useAuthTokenWithAudience("vehicles");
    const dispatchLocationsAuthToken =
      useAuthTokenWithAudience("dispatchLocations");
    const {
      control,
      handleSubmit,
      setValue,
      getValues,
      trigger,
      formState: { errors, isValid },
    } = useForm({
      mode: "onChange",
      defaultValues: store.getVehicleFormPayloadOnEdit() || {},
    });

    React.useEffect(() => {
      if (!dispatchLocationStore.authToken) {
        dispatchLocationStore.setAuthToken(dispatchLocationsAuthToken);
      }
    }, [dispatchLocationsAuthToken]);

    React.useEffect(() => {
      if (dispatchLocationStore.authToken) {
        dispatchLocationStore.getDispatchLocations();
      }
    }, []);

    //validate on mount
    React.useEffect(() => {
      trigger();
    }, [trigger]);

    const toggleAmenity = (amenity: Amenity) => {
      const amenities = getValues("amenities");
      const toggledAmenities = xor(amenities, [amenity]);
      setValue("amenities", toggledAmenities);
    };

    const onSubmit = async (formData: VehicleFormData) => {
      store.submitEditForm(vehiclesAuthToken, formData).finally(onClose);
    };

    return (
      <FlexContainer direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer
            direction="column"
            w="100%"
            rowGap={2.5}
            sx={{ maxHeight: "60vh", overflow: "scroll", marginBottom: "25px" }}
          >
            <FlexContainer w="100%" columnGap={6} align="center">
              <H3 color={palette?.black?.main} sx={{ minWidth: "300px" }}>
                License plate number
              </H3>
              <Controller
                control={control}
                name="licensePlate"
                rules={{
                  required: "This field is required!",
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    required
                    sx={{
                      margin: 0,
                      marginTop: theme?.spacing(1),
                      ".MuiOutlinedInput-root": {
                        height: 40,
                      },
                      fieldset: {
                        borderColor: palette?.black?.plus70,
                      },
                    }}
                    errorMessage={errors.licensePlate?.message}
                  />
                )}
              />
              <Controller
                name="spabCertified"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    name="SPAB certified"
                    labelPlacement="end"
                    checked={field.value}
                  />
                )}
              />
            </FlexContainer>
            <FlexContainer>
              <Box
                sx={{
                  textAlign: "left",
                  backgroundColor: palette?.red?.plus80,
                  borderRadius: "4px",
                  padding: "8px 16px",
                  marginTop: "20px",
                }}
              >
                <H4 color={palette?.black?.plus30}>
                  <span role="img" aria-label="note">
                    ☝️
                  </span>{" "}
                  License plate number could be different based on your vehicle
                  region registration.
                </H4>
                <H4 color={palette?.black?.plus30}>
                  <span role="img" aria-label="note">
                    ☝️☝️️️
                  </span>{" "}
                  If you don’t have the license plate number, no worries. You
                  can add it later. Placeholder will be temporarily used.
                </H4>
              </Box>
            </FlexContainer>
            <FlexContainer w="100%" columnGap={6} align="center">
              <H3 color={palette?.black?.main} sx={{ minWidth: "300px" }}>
                Seats total
              </H3>
              <AmountFieldStyled>
                <div className="field">
                  <button
                    type="button"
                    onClick={() =>
                      setValue(
                        "seatsTotal",
                        Number(getValues("seatsTotal")) - 1
                      )
                    }
                  >
                    <MinusIcon />
                  </button>
                  <Controller
                    control={control}
                    name="seatsTotal"
                    rules={{
                      required: "This field is required!",
                      min: {
                        value: 1,
                        message:
                          "The total number of seats must be more than 0",
                      },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        required
                        errorMessage={errors.seatsTotal?.message}
                        onChange={(e) =>
                          setValue("seatsTotal", Number(e.target.value), {
                            shouldValidate: true,
                          })
                        }
                      />
                    )}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setValue(
                        "seatsTotal",
                        Number(getValues("seatsTotal")) + 1
                      )
                    }
                  >
                    <PlusIcon />
                  </button>
                </div>
              </AmountFieldStyled>
            </FlexContainer>
            <FlexContainer>
              <Controller
                control={control}
                name="wheelchairAccessible"
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    name="Wheelchair accessibility"
                    labelPlacement="end"
                    checked={field.value}
                  />
                )}
              />
            </FlexContainer>
            {getValues("wheelchairAccessible") && (
              <FlexContainer w="100%" columnGap={6} align="center">
                <H3
                  color={palette.black.main}
                  sx={{ minWidth: "300px", paddingLeft: "32px" }}
                >
                  Seats removed per wheelchair
                </H3>
                <AmountFieldStyled>
                  <div className="field">
                    <button
                      type="button"
                      onClick={() =>
                        setValue(
                          "seatsRemovedPerWheelchair",
                          Number(getValues("seatsRemovedPerWheelchair")) - 1
                        )
                      }
                    >
                      <MinusIcon />
                    </button>
                    <Controller
                      name="seatsRemovedPerWheelchair"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          {...field}
                          required
                          onChange={(e) =>
                            setValue(
                              "seatsRemovedPerWheelchair",
                              Number(e.target.value)
                            )
                          }
                        />
                      )}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setValue(
                          "seatsRemovedPerWheelchair",
                          Number(getValues("seatsRemovedPerWheelchair")) + 1
                        )
                      }
                    >
                      <PlusIcon />
                    </button>
                  </div>
                </AmountFieldStyled>
              </FlexContainer>
            )}
            <FlexContainer w="100%" columnGap={6} align="center">
              <H3 color={palette?.black?.main} sx={{ minWidth: "300px" }}>
                Dispatch location
              </H3>
              <Controller
                name="dispatchLocationId"
                control={control}
                rules={{
                  required: "This field is required",
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    items={
                      dispatchLocationStore?.dispatchLocations?.map(
                        (d: DispatchLocation) => ({
                          name: d.name,
                          value: d.id,
                        })
                      ) || []
                    }
                    sx={{ width: "177.5px" }}
                  />
                )}
              />
            </FlexContainer>
            <FlexContainer
              w="100%"
              direction="column"
              sx={{ marginTop: "50px" }}
            >
              <H3>Amenities</H3>
              <FlexContainer
                w="100%"
                justify="flex-start"
                wrap="wrap"
                columnGap={1.5}
                rowGap={3}
              >
                {AmenityValues.map((amenity, index) => {
                  return (
                    <Controller
                      key={index}
                      name="amenities"
                      control={control}
                      render={({ field }) => (
                        <BigIconSelect
                          title={mapAmenityToName(amenity)}
                          isActive={field.value.includes(amenity)}
                          onClick={() => toggleAmenity(amenity)}
                        >
                          <AmenityIcon type={amenity} />
                        </BigIconSelect>
                      )}
                    />
                  );
                })}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer w="100%" align="flex-end" justify="flex-end">
            <FlexContainer sx={{ padding: "20px 0px" }} columnGap={2}>
              <Button typestyle="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                typestyle="primary"
                type="submit"
                disabled={!isValid}
                async
              >
                Save
              </Button>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  });

export default VehiclesEditForm;
