import { QueryKey, useQuery } from "react-query";

import { getRevenueShareConfig, listRevenueShareConfigs } from "@busie/api";
import {
  queryClient,
  STALE_TIMES,
  useAuthTokenWithAudience,
} from "@busie/core";

const REVENUE_SHARE_CONFIG_QUERY_KEY: QueryKey = "REVENUE_SHARE_CONFIG";

export const invalidateRevenueShareConfigQueries = () => {
  queryClient.invalidateQueries(REVENUE_SHARE_CONFIG_QUERY_KEY);
};

export const useGetRevenueShareConfig = (id = "") => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [REVENUE_SHARE_CONFIG_QUERY_KEY, id],
    () => getRevenueShareConfig(paymentsAuthToken, id),
    {
      enabled: !!paymentsAuthToken && !!id,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return result;
};

export const useDefaultRevenueShareConfig = (organizationId = "") => {
  const paymentsAuthToken = useAuthTokenWithAudience("payments");

  const result = useQuery(
    [REVENUE_SHARE_CONFIG_QUERY_KEY, organizationId],
    () => listRevenueShareConfigs(paymentsAuthToken, organizationId),
    {
      enabled: !!paymentsAuthToken && !!organizationId,
      staleTime: STALE_TIMES.DEFAULT,
    }
  );

  return {
    ...result,
    data: (result.data || []).find((rs) => rs.configType === "DEFAULT"),
  };
};
